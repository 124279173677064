import React, { useState } from 'react';
import NumberInput from '../../add-digs/NumberInput';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { SimpleRoom } from 'models/property';
import ManageListingRoom from './ManageListingRoom';

import styled from 'styled-components';

import theme from 'config/theme';
import routes from 'config/routes';
import Button from 'lib/Button';
import Divider from 'lib/Divider';

const { colors, fontWeights } = theme;

interface AvailabilityModalContentProps {
	availablePlaces: number;
	rooms: SimpleRoom[];
	id: string;
	onRoomActiveChange(val: number): void;
	onUpdateRoomStatus(updatedRooms: SimpleRoom[]): void;
	onAvailabilityChange(val: number): void;
}

const AvailabilityModalContent = ({
	availablePlaces,
	rooms,
	id,
	onRoomActiveChange,
	onUpdateRoomStatus,
	onAvailabilityChange,
}: AvailabilityModalContentProps) => {
	const [activeStateOfRooms, setActiveStateOfRooms] = useState(rooms);

	const onActiveStatusChange = (updatedRoom: SimpleRoom) => {
		const newRooms = activeStateOfRooms.map(room => ({
			...room,
			active: room.uuid === updatedRoom.uuid ? updatedRoom.active : room.active,
		}));

		setActiveStateOfRooms(newRooms);
		onUpdateRoomStatus(newRooms);
	};

	return (
		<AvailableContent>
			<AvailableHeaderContainer>
				<AvailableHeader>How many places are currently available</AvailableHeader>
			</AvailableHeaderContainer>
			<NumberInput
				value={availablePlaces}
				label="Number of available places"
				isBlue
				onChange={onAvailabilityChange}
			/>
			{rooms.map((live, i) => (
				<ManageListingRoom
					key={i}
					onChange={onRoomActiveChange}
					onUpdate={onActiveStatusChange}
					room={live}
				/>
			))}
			{!rooms.length && (
				<>
					<Divider />
					<SectionHeader>No rooms added</SectionHeader>
					<Text>
						You have no rooms added to this listing. Add your available rooms by editing this
						listing.
					</Text>
					<Button isOutline maxWidth={180} link={routes.edit_digs.replace(':id?', `${id}`)}>
						Edit rooms
					</Button>
				</>
			)}
		</AvailableContent>
	);
};

export default AvailabilityModalContent;

const AvailableContent = styled.div`
	padding: 16px 48px;
	padding-bottom: 32px;
	overflow-y: auto;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding: 24px;
		padding-bottom: 136px;
	}
`;

const AvailableHeaderContainer = styled.div`
	display: flex-start;
	margin-bottom: 10px;
`;

const AvailableHeader = styled.div`
	font-weight: ${fontWeights.bold};
	font-size: 16px;
`;

const SectionHeader = styled.div`
	text-align: center;
	font-size: 16px;
	margin: auto;
	width: 100%;
	font-weight: ${fontWeights.bold};
	margin-top: 10px;
	padding-top: 10px;
	margin-bottom: 24px;
`;

const Text = styled.div`
	text-align: center;
	font-size: 16px;
	margin: auto;
	margin-bottom: 24px;
	width: 100%;
	line-height: 20px;
`;
