import React, { useState } from 'react';
import { SimpleRoom } from 'models/property';
import Toggle from 'components/common/toggle';

import styled from 'styled-components';
import theme from 'config/theme';

const { colors, fontWeights } = theme;

interface ManageListingRoomProps {
	room: SimpleRoom;
	onChange(val: number): void;
	onUpdate(updatedRoom: SimpleRoom): void;
}

export const ManageListingRoom = ({ room, onUpdate, onChange }: ManageListingRoomProps) => {
	const [active, setActive] = useState(room.active);
	const [activeRoom, setActiveRoom] = useState(room);

	const handleToggle = () => {
		setActive(!active);
		const updateRoom = { ...activeRoom, active: !active };
		setActiveRoom(updateRoom);
		onUpdate(updateRoom);

		if (!active) {
			onChange(1);
		} else {
			onChange(-1);
		}
	};

	return (
		<RoomContainer>
			<RoomTitle>{room.title}</RoomTitle>
			{Boolean(room.furnished) && Boolean(room.bathroom_type) && (
				<RoomSubTitle>
					{room.furnished != 'none'
						? (room.furnished || '').charAt(0).toUpperCase() + room.furnished.slice(1)
						: 'Not'}{' '}
					furnished &#8226;{' '}
					{(room.bathroom_type || '').charAt(0).toUpperCase() + room.bathroom_type.slice(1)}{' '}
					bathroom
				</RoomSubTitle>
			)}
			<RoomAvailableContainer>
				<RoomAvailableTextContainer>
					<RoomAvailableTitle>Room {active ? 'available' : 'unavailable'}</RoomAvailableTitle>
					<RoomAvailableDescription>
						This room will show as {active ? 'available on your listing page' : '"Sold out"'}
					</RoomAvailableDescription>
				</RoomAvailableTextContainer>
				<Toggle
					id={`active-toggle-${room.uuid}`}
					checked={active}
					onChange={() => {
						handleToggle();
					}}
				/>
			</RoomAvailableContainer>
		</RoomContainer>
	);
};

export default ManageListingRoom;

const RoomContainer = styled.div`
	display: flex-start;
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid ${colors.grey10};
`;

const RoomAvailableTextContainer = styled.div``;

const RoomAvailableContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 14px;
`;

const RoomTitle = styled.div`
	font-weight: ${fontWeights.bold};
	font-size: 20px;
`;

const RoomAvailableTitle = styled.div`
	font-weight: ${fontWeights.normal};
	font-size: 16px;
`;

const RoomAvailableDescription = styled.div`
	font-weight: ${fontWeights.normal};
	font-size: 12px;
	color: ${colors.grey60};
	margin-top: 2px;
	line-height: 1.2em;

	@media (max-width: 500px) {
		padding-right: 50px;
	}
`;

const RoomSubTitle = styled.div`
	font-weight: ${fontWeights.normal};
	font-size: 14px;
	color: ${colors.grey60};
	margin-top: 10px;
`;
