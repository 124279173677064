import React from 'react';

import styled from 'styled-components';
import theme from 'config/theme';
import { Notifications } from './types';
import useRouter from 'hooks/useRouter';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';

const { colors, fontWeights } = theme;

interface LandlordNotificationsProps {
	notifications: Notifications;
}
const LandlordNotifications = ({ notifications }: LandlordNotificationsProps) => {
	const [goTo] = useRouter();
	return (
		<Container>
			<TitleContainer>Here's what you missed</TitleContainer>
			<NotificationsContainer>
				{notifications.notifications.map((notification, i) => (
					<NotificationItem key={i}>
						<NotificationRow>
							<NotificationLeftContainer>
								<NotificationIcon src={notification.icon} />
								<NotificationInfoContainer>
									<NotificationTitle>{notification.title}</NotificationTitle>
									<NotificationInfoText dangerouslySetInnerHTML={{ __html: notification.body }} />
								</NotificationInfoContainer>
							</NotificationLeftContainer>
							<NotificationActionButton onClick={() => goTo(notification.button_redirect)}>
								{notification.button_title}
							</NotificationActionButton>
						</NotificationRow>
						{i != notifications.notifications.length - 1 && <Divider />}
					</NotificationItem>
				))}
			</NotificationsContainer>
		</Container>
	);
};

export default LandlordNotifications;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: flex-start;
	background-color: ${colors.darkTurquoiseFaded};
	border: 2px solid #047d8b;
	border-radius: 16px;
	padding: 24px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		margin: 16px;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	font-size: 25px;
	font-weight: ${fontWeights.bold};
`;

const NotificationsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	gap: 16px;
`;

const NotificationItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	gap: 16px;
`;

const NotificationInfoContainer = styled.div`
	display: flex;
	gap: 4px;
	flex-direction: column;
`;

const NotificationInfoText = styled.div`
	display: flex;
	font-size: 16px;
	white-space: pre-wrap;
	flex-wrap: wrap;
`;

const NotificationTitle = styled.div`
	display: flex;
	font-weight: ${fontWeights.bold};
	font-size: 16px;
`;

const NotificationRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 8px;
`;

const NotificationActionButton = styled.div`
	display: flex;
	font-weight: ${fontWeights.bold};

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

const NotificationIcon = styled.img`
	width: 24px;
	height: 24px;
	align-items: center;
`;

const NotificationLeftContainer = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
`;

const Divider = styled.div`
	border-bottom: 1px solid ${colors.darkBlue};
	width: 100%;
`;
