import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'config/theme';
import { ReactComponent as PointerTriangleSvg } from 'assets/images/invoicing/pointer-triangle.svg';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { ProductTourStep } from './productTours';

const { colors } = theme;

const pointerHeight = 16;
const pointerOffset = 24;
const pointerWidth = 24;
const mobilePadding = 16;

const ProductTour = ({
	onComplete,
	productTourSteps,
}: {
	onComplete(): void;
	productTourSteps: ProductTourStep[];
}) => {
	const tourPointerRef = useRef<any>(null);
	const tourPointerArrowRef = useRef<any>(null);
	const [tourStep, setTourStep] = useState(0);
	const screenWidth = useScreenWidth();

	useEffect(() => {
		if (!tourPointerRef.current || !tourPointerArrowRef.current) {
			return;
		}
		const targetElement: any = document.getElementsByClassName(
			productTourSteps[tourStep].elementClass,
		)[0];

		const tourPointer = tourPointerRef.current;
		const tourPointerArrow = tourPointerArrowRef.current;
		if (!targetElement) {
			tourPointer.style.display = 'none';
			tourPointerArrow.style.display = 'none';
			return;
		}
		const targetElementPos = targetElement.getBoundingClientRect();
		const screenMidPoint = window.innerWidth / 2;
		const targetMidPoint = targetElementPos.left + targetElementPos.width / 2;

		tourPointer.style.display = 'block';
		tourPointer.style.top = `${targetElementPos.top +
			targetElement.offsetHeight +
			pointerHeight +
			(window.pageYOffset || document.documentElement.scrollTop)}px`;

		tourPointerArrow.style.display = 'block';
		tourPointerArrow.style.top = -pointerHeight;

		if (screenWidth < BREAKPOINT_TABLET_PX) {
			tourPointer.style.left = `${mobilePadding}px`;
			tourPointer.style.right = `${mobilePadding}px`;
			tourPointerArrow.style.right = 'unset';
			tourPointerArrow.style.left = targetMidPoint - pointerWidth / 2 - mobilePadding;
			const yOffset = 140;
			const targetTop =
				targetElement.getBoundingClientRect().top -
				targetElement.offsetHeight +
				window.pageYOffset -
				yOffset;

			window.scrollTo({ top: Math.max(targetTop, 220), behavior: 'smooth' });
			return;
		}

		if (targetMidPoint < screenMidPoint) {
			// pointer on left
			tourPointer.style.left = `${targetMidPoint - pointerOffset - pointerWidth / 2}px`;
			tourPointerArrow.style.right = 'unset';
			tourPointerArrow.style.left = pointerOffset;
		} else {
			// pointer on right
			tourPointer.style.left = `${targetMidPoint -
				tourPointer.getBoundingClientRect().width +
				pointerOffset +
				pointerWidth / 2}px`;
			tourPointerArrow.style.left = 'unset';
			tourPointerArrow.style.right = pointerOffset;
		}
	}, [tourStep]);

	const handleNextTourTip = () => {
		setTourStep(prev => (prev < productTourSteps.length - 1 ? prev + 1 : prev));
	};

	const handleTourEnd = () => {
		setTourStep(0);
		onComplete();
	};

	return (
		<>
			{
				<TourBox ref={tourPointerRef}>
					<Row>
						<TourTitle>{productTourSteps[tourStep].title}</TourTitle>
						<TourTitle>
							{tourStep + 1}/{productTourSteps.length}
						</TourTitle>
					</Row>
					<TourDescription>{productTourSteps[tourStep].description}</TourDescription>
					<Row>
						<TourSkipButton onClick={handleTourEnd}>Skip tour</TourSkipButton>
						{tourStep < productTourSteps.length - 1 && (
							<TourButton onClick={handleNextTourTip}>Next tip</TourButton>
						)}
						{tourStep === productTourSteps.length - 1 && (
							<TourButton onClick={handleTourEnd}>Close</TourButton>
						)}
					</Row>
					<TourPointer ref={tourPointerArrowRef} />
				</TourBox>
			}
		</>
	);
};

export default ProductTour;

const TourBox = styled.div`
	background: ${colors.brightBlue};
	color: ${colors.white};
	position: absolute;
	min-width: 240px;
	max-width: 300px;
	padding: 16px;
	border-radius: 16px;
	z-index: 100;
	display: none;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		max-width: 100vw;
		left: 0;
		right: 0;
	}
`;

const TourPointer = styled(PointerTriangleSvg)`
	position: absolute;
	z-index: 100;
	display: none;
`;

const TourTitle = styled.div`
	font-weight: 600;
	font-size: 16px;
`;

const TourDescription = styled.div`
	margin-top: 8px;
	font-size: 14px;
	margin-bottom: 16px;
	line-height: 18px;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const TourSkipButton = styled.div`
	color: ${colors.white};
	cursor: pointer;
	font-weight: 600;
	font-size: 14px;
`;

const TourButton = styled(TourSkipButton)`
	background: ${colors.darkBlue};
	padding: 8px 16px;
	border-radius: 8px;
`;
