import React from 'react';
import styled from 'styled-components';
import theme from 'config/theme';
import useRouter from 'hooks/useRouter';
import routes from 'config/routes';
import { BREAKPOINT_MOBILE_PX, BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import { PaginatedInbox } from 'models/inbox';
import dateService from 'services/dateService';

const { colors, fontWeights } = theme;

interface LandlordPendingConversationProps {
	conversations: PaginatedInbox;
}

const LandlordPendingConversations = ({ conversations }: LandlordPendingConversationProps) => {
	const [goTo] = useRouter();
	return (
		<Container empty={conversations.results.length == 0}>
			<HeaderContainer>
				<TitleContainer>New enquiries</TitleContainer>
				<ViewInboxContainer onClick={() => goTo(routes.inbox)}>
					View inbox
				</ViewInboxContainer>
			</HeaderContainer>
			{conversations.results.length > 0 ? (
				conversations.results.map(chat => (
					<InboxChatContainer>
						<InboxChatIconColumn>
							<ProfilePictureIcon src={chat.other_users[0].profile_picture.url} />
						</InboxChatIconColumn>
						<InboxChatRow>
							<InboxChatMiddleSection>
								<ChatRowHeaderContainer>
									<InboxChatText bold>
										{chat.other_users[0].first_name} {chat.other_users[0].last_name}
									</InboxChatText>
									<ViewEnquiryContainer onClick={() => goTo(routes.inbox)}>
										View enquiry
									</ViewEnquiryContainer>
								</ChatRowHeaderContainer>
								<InboxChatText small>{chat.property_title}</InboxChatText>
								<InboxChatText>
									{' '}
									{dateService.formatForDisplay(chat.latest_message?.date_created || '', true)}
								</InboxChatText>
								<InboxChatText bold small color={colors.orange}>
									{chat.status_readable}
								</InboxChatText>
							</InboxChatMiddleSection>
						</InboxChatRow>
					</InboxChatContainer>
				))
			) : (
				<InboxContainer>You have no new enquiries</InboxContainer>
			)}
		</Container>
	);
};

export default LandlordPendingConversations;

const Container = styled.div<{ empty: boolean }>`
	display: flex;
	gap: ${({ empty }) => (empty ? `32px` : '16px')};
	flex-direction: column;
	width: 30%;
	min-width: 300px;
	padding: 24px 0px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		max-width: ${BREAKPOINT_MOBILE_PX};
		width: 100%;
		gap: 24px;
	}
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 8px;
	width: 100%;
`;

const InboxContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;
	gap: 16px;
`;

const TitleContainer = styled.div`
	display: flex;
	font-weight: ${fontWeights.bold};
	font-size: 24px;
	align-items: center;
	flex-wrap: wrap;
`;

const ChatRowHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ViewInboxContainer = styled.div`
	display: flex;
	border-radius: 10px;
	box-sizing: border-box;
	border: 2px solid ${colors.darkBlue};
	padding: 8px;
	font-weight: ${fontWeights.bold};
	cursor: pointer;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-wrap: wrap;
	}
`;

const InboxChatContainer = styled.div`
	padding: 8px 8px;
	padding-bottom: 8px;
	display: flex;
	gap: 8px;
	justify-content: flex-start;
	align-items: flex-start;
	border-bottom: 1px solid ${colors.grey10};
	position: relative;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		width: unset;
		min-width: unset;
	}
`;

const InboxChatIconColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
`;

const ProfilePictureIcon = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 40px;
`;

const InboxChatRow = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex: 1;
`;

const InboxChatMiddleSection = styled.div`
	flex: 1;
	white-space: nowrap;
	width: 0;
`;

const InboxChatText = styled.div<{ color?: string; bold?: boolean; small?: boolean }>`
	font-weight: 400;
	margin-bottom: 4px;
	text-overflow: ellipsis;
	overflow: hidden;
	color: ${({ color }) => color};
	font-weight: ${({ bold }) => (bold ? 600 : 400)};
	font-size: ${({ small }) => (small ? 14 : 16)}px;
	line-height: 20px;
`;

const ViewEnquiryContainer = styled.div`
	cursor: pointer;
	font-size: 14px;
	font-weight: ${fontWeights.bold};
	color: ${colors.linkBlue};
`;
