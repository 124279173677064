import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { ReactComponent as EmptyStateIllustration } from 'assets/images/invoicing/empty-state.svg';
import api from 'api';
import { Loader } from 'lib/Loader';
import SubTitle from 'lib/SubTitle';
import Button from 'lib/Button';
import routes from 'config/routes';
import { Link } from 'react-router-dom';
import numberFormatService from 'services/numberFormatService';
import Input from 'lib/Input';
import { ReactComponent as SearchSvg } from 'assets/images/icons/search-outline.svg';
import { ReactComponent as QuestionSvg } from 'assets/images/icons/question.svg';
import { ReactComponent as DocumentSvg } from 'assets/icons/document.svg';
import { ReactComponent as InfoCircle } from 'assets/images/icons/info-circle-outline.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { SelectOption } from 'models/selectOption';
import { Invoice, InvoiceStatus, OutstandingDetails } from 'models/invoices';
import dateService from 'services/dateService';
import modalContainer from 'containers/modal';
import ModalEnum from 'models/modalEnum';
import PayNowModalContent from './PayNowModalContent';
import InvoiceDetailsModalContent from './InvoiceDetailsModalContent';
import invoiceService from './invoiceService';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import { useScreenWidth } from 'hooks/useScreenWidth';
import QueryInvoiceModalContent from './QueryInvoiceModalContent';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import InvoicesProductTour from './InvoicesProductTour';

const { colors } = theme;

enum Filter {
	All = 'All',
	Outstanding = 'Outstanding',
	Paid = 'Paid',
	Queried = 'Queried',
	Draft = 'Draft',
}

const chips: SelectOption[] = [
	{ label: 'All', value: Filter.All },
	{ label: 'Outstanding', value: Filter.Outstanding },
	{ label: 'Paid', value: Filter.Paid },
	{ label: 'Queried', value: Filter.Queried },
	{ label: 'Draft', value: Filter.Draft },
];

const SEARCH_DEBOUNCE_MILLISECONDS = 700;

const InvoicesDashboard = () => {
	const [invoices, setInvoices] = useState<Invoice[]>([]);
	const [nextPageUrl, setNextPageUrl] = useState<string>('');
	const [isLoading, setIsLoading] = useState(true);
	const [search, setSearch] = useState<string>();
	const [selectedFilter, setSelectedFilter] = useState(Filter.All);
	const [outstanding, setOutstanding] = useState<OutstandingDetails>();
	const [timeoutId, setTimeoutId] = useState<number>();
	const [isSearching, setIsSearching] = useState(false);
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth <= BREAKPOINT_TABLET_PX;
	const [showInvoicesTour, setShowInvoicesTour] = useState(false);

	useEffect(() => {
		if (search === undefined) {
			return;
		}

		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		setTimeoutId(
			setTimeout(() => {
				if (search === null) {
					return;
				}

				refreshInvoices(search || '', selectedFilter);
			}, SEARCH_DEBOUNCE_MILLISECONDS) as any,
		);
		// eslint-disable-next-line
	}, [search]);

	useEffect(() => {
		Promise.all([
			api.invoicing.getInvoices(),
			api.invoicing.getOutstanding(),
			api.userV2.getProfile(),
		]).then(([invoicesResponse, response2, userProfileV2Response]) => {
			setInvoices(invoicesResponse.data.results);
			setNextPageUrl(invoicesResponse.data.next);
			setIsLoading(false);
			setOutstanding(response2.data);

			if (
				invoicesResponse.data.results.length > 0 &&
				!userProfileV2Response.data.has_completed_invoicing_tour
			) {
				setShowInvoicesTour(true);
			}
		});
	}, []);

	const refreshInvoices = async (
		term: string,
		selectedFilter: string,
		nextPage: boolean = false,
	) => {
		setIsSearching(true);

		if (nextPage && nextPageUrl) {
			const pageNumber = +(new URLSearchParams(new URL(nextPageUrl).search).get('page') || '');
			const response = await api.invoicing.getInvoices({
				search: term,
				filter: !selectedFilter || selectedFilter === Filter.All ? '' : selectedFilter,
				page: pageNumber,
			});

			setIsSearching(false);

			setInvoices(prev => [...prev, ...response.data.results]);
			setNextPageUrl(response.data.next);
			return;
		}

		setNextPageUrl('');
		const response = await api.invoicing.getInvoices({
			search: term,
			filter: !selectedFilter || selectedFilter === Filter.All ? '' : selectedFilter,
		});

		setIsSearching(false);

		setInvoices(response.data.results);
		setNextPageUrl(response.data.next);
	};

	const handlePayNowClick = () => {
		if (!outstanding) {
			return;
		}
		if (+(outstanding.total_outstanding || 0) <= 0) {
			return;
		}
		modalContainer.open(
			ModalEnum.Generic,
			<PayNowModalContent
				reference={outstanding.reference}
				amount={numberFormatService.formatCurrency(
					+(outstanding.total_outstanding || 0),
					outstanding.currency?.symbol,
					true,
				)}
				onClose={modalContainer.close}
			/>,
			{ width: 450 },
		);
	};

	const handleQueryInvoice = (invoice: Invoice) => {
		if (invoice.queried) {
			return;
		}

		modalContainer.open(
			ModalEnum.Generic,
			<QueryInvoiceModalContent
				invoice={invoice}
				onClose={modalContainer.close}
				onQueried={() => {
					setInvoices(items =>
						items.map(item => ({
							...item,
							queried: item.uuid === invoice.uuid ? true : item.queried,
						})),
					);
					modalContainer.close();
				}}
			/>,
			{ width: 500 },
		);
	};

	const handleInvoiceClick = (invoice: Invoice) => {
		modalContainer.open(
			ModalEnum.Generic,
			<InvoiceDetailsModalContent
				invoice={invoice}
				onClose={modalContainer.close}
				onQueryClick={() => handleQueryInvoice(invoice)}
			/>,
			{ width: 500 },
		);
	};

	const handleFilterChange = (value: Filter) => {
		setSelectedFilter(value);
		refreshInvoices(search || '', value);
	};

	const handleViewMoreClick = () => {
		refreshInvoices(search || '', selectedFilter, true);
	};

	const handleTourComplete = () => {
		setShowInvoicesTour(false);
		api.userV2.updateProfile({
			has_completed_invoicing_tour: true,
		});
	};

	if (isLoading) {
		return (
			<Container>
				<LoaderContainer>
					<Loader />
				</LoaderContainer>
			</Container>
		);
	}

	if (!invoices.length && search === '' && selectedFilter === Filter.All && !isSearching) {
		return (
			<Container>
				<EmptyStateContainer>
					<EmptyStateIllustration />
					<EmptyStateHeaderText>Looks like you have no invoices yet</EmptyStateHeaderText>
					<SubTitle>
						Place tenants in your property and manage your invoices from your invoice tab!
					</SubTitle>
					<SmallText>
						If you’ve placed a tenant through DigsConnect and do not see your invoice yet, please
						contact support.
					</SmallText>
					<Button link={routes.contact_us}>Contact support</Button>
					<SmallText>
						Still have questions? Check out our{' '}
						<Link component={InlineLink} to={routes.faqs}>
							Payments FAQ
						</Link>
					</SmallText>
				</EmptyStateContainer>
			</Container>
		);
	}

	if (isMobile) {
		return (
			<>
				<ContainerMobile>
					<MobileTopSection>
						<MobileTitleRow>
							<MainTitle>Invoices</MainTitle>
							<HelpText onClick={() => setShowInvoicesTour(true)}>
								Need help? View Tutorial
							</HelpText>
						</MobileTitleRow>
						<SubTitleTextMobile>
							Please note that payments can take up to 48 hrs to reflect.
						</SubTitleTextMobile>
						{outstanding && (
							<>
								<MainSubTitle>Total outstanding:</MainSubTitle>
								<Row fullWidth justifyContent="space-between">
									<InvoiceTotal>
										<span style={{ display: 'block' }} className="invoices-total">
											{numberFormatService.formatCurrency(
												+(outstanding.total_outstanding || 0),
												outstanding.currency?.symbol,
											)}
										</span>
									</InvoiceTotal>
									<ButtonContainer>
										<Button
											isDisabled={+(outstanding.total_outstanding || 0) <= 0}
											isFullWidth
											height={40}
											onClick={handlePayNowClick}
										>
											Pay now
										</Button>
									</ButtonContainer>
								</Row>
							</>
						)}
						<MobileChipsContainer>
							{chips.map(chip => (
								<FilterChipContainer
									active={chip.value === selectedFilter}
									className={`filter-${chip.value}`.toLowerCase()}
									key={chip.label}
									onClick={() => handleFilterChange(chip.value)}
								>
									{chip.label}
								</FilterChipContainer>
							))}
						</MobileChipsContainer>
						<PaddingBottom>
							<Input
								hideErrorMargin
								lightBackground
								prefixIcon={<SearchIcon />}
								placeholder="Search by tenant or invoice no."
								value={search}
								onChange={value => setSearch(value as string)}
							/>
						</PaddingBottom>
					</MobileTopSection>
					{!Boolean(invoices.length) && selectedFilter !== Filter.All && !isSearching && (
						<EmptyStateText>
							You have no {selectedFilter.toLowerCase()} invoices{' '}
							{Boolean(search) && <>that match this search</>}
						</EmptyStateText>
					)}
					{isSearching ? (
						<Container>
							<Loader />
						</Container>
					) : (
						<>
							{invoices.map(invoice => (
								<MobileInvoiceContainer
									key={invoice.uuid}
									onClick={() => handleInvoiceClick(invoice)}
								>
									<MobileInvoiceItemContent>
										<Row justifyContent="space-between">
											<MobileBoldText>
												{invoice.invoice_number ? (
													<MarginRight>{invoice.invoice_number}</MarginRight>
												) : (
													<MarginRight>Draft</MarginRight>
												)}
												{invoice.queried && <MobileGreyTextWrapper>Queried</MobileGreyTextWrapper>}
											</MobileBoldText>
											<MobileBoldText>
												{numberFormatService.formatCurrency(
													+invoice.total_amount,
													invoice.currency?.symbol,
													true,
												)}
											</MobileBoldText>
										</Row>
										<Row justifyContent="space-between">
											<MobileTenantName
												className="invoice-tenant-details-icon"
												onClick={e => {
													e.preventDefault();
													e.stopPropagation();
													window.location.href = invoice.conversation_url;
												}}
												href={invoice.conversation_url}
											>
												{invoice.tenant_name}
											</MobileTenantName>
											<MobileStatusText
												style={{ color: invoiceService.getStatusColor(invoice.status) }}
											>
												{invoice.status}
											</MobileStatusText>
										</Row>
									</MobileInvoiceItemContent>
									<Row className="view-invoice-details-button">
										<MobileIconRight />
									</Row>
								</MobileInvoiceContainer>
							))}
							{nextPageUrl && (
								<NextButtonContainer>
									<Button isOutline onClick={handleViewMoreClick}>
										Show more
									</Button>
								</NextButtonContainer>
							)}
						</>
					)}
				</ContainerMobile>
				{showInvoicesTour && <InvoicesProductTour onComplete={handleTourComplete} />}
			</>
		);
	}

	return (
		<>
			<Container>
				<HeaderContainer>
					<HeaderSection>
						<div>
							<FlexRow>
								<MainTitle>Invoices</MainTitle>
								<HelpText onClick={() => setShowInvoicesTour(true)}>
									Need help? View Tutorial
								</HelpText>
							</FlexRow>
							<SubTitleText>
								Please note that payments can take up to 48 hrs to reflect.
							</SubTitleText>
						</div>
					</HeaderSection>
					<HeaderSection>
						{outstanding && (
							<>
								<MainSubTitle>Total outstanding:</MainSubTitle>
								<InvoiceTotal>
									<span style={{ display: 'block' }} className="invoices-total">
										{numberFormatService.formatCurrency(
											+(outstanding.total_outstanding || 0),
											outstanding.currency?.symbol,
										)}
									</span>
								</InvoiceTotal>
								<ButtonContainer>
									<Button
										isFullWidth
										height={40}
										onClick={handlePayNowClick}
										isDisabled={+(outstanding.total_outstanding || 0) <= 0}
									>
										Pay now
									</Button>
								</ButtonContainer>
							</>
						)}
					</HeaderSection>
				</HeaderContainer>
				<FilterBarContainer>
					<FilterChipsContainer>
						{chips.map(chip => (
							<FilterChipContainer
								active={chip.value === selectedFilter}
								key={chip.label}
								onClick={() => handleFilterChange(chip.value)}
								className={`filter-${chip.value}`.toLowerCase()}
							>
								{chip.label}
							</FilterChipContainer>
						))}
					</FilterChipsContainer>
					<Input
						hideErrorMargin
						lightBackground
						prefixIcon={<SearchIcon />}
						placeholder="Search by tenant or invoice no."
						value={search}
						onChange={value => setSearch(value as string)}
					/>
				</FilterBarContainer>
				{!Boolean(invoices.length) && selectedFilter !== Filter.All && !isSearching && (
					<EmptyStateText>
						You have no {selectedFilter.toLowerCase()} invoices{' '}
						{Boolean(search) && <>that match this search</>}
					</EmptyStateText>
				)}
				{(Boolean(invoices.length) || isSearching) && (
					<TableContainer>
						<TableRows>
							<TableHeaders>
								<TableHeaderItem>Date</TableHeaderItem>
								<TableHeaderItem>Invoice no.</TableHeaderItem>
								<TableHeaderItem flex={2}>Tenant name</TableHeaderItem>
								<TableHeaderItem>Amount</TableHeaderItem>
								<TableHeaderItem>Payment due</TableHeaderItem>
								<TableHeaderItem flex={0.7}>Status</TableHeaderItem>
								<TableHeaderItem flex={0.8}></TableHeaderItem>
								<TableHeaderItem></TableHeaderItem>
							</TableHeaders>
							{isSearching ? (
								<Container>
									<Loader />
								</Container>
							) : (
								<>
									{invoices.map(invoice => (
										<TableRow key={invoice.uuid}>
											<TableRowColumn>
												{dateService.formatForDisplay(invoice.date_invoiced)}
											</TableRowColumn>
											<TableRowColumn>
												{!!invoice.invoice_number ? invoice.invoice_number : 'Draft'}
											</TableRowColumn>
											<TableRowColumn flex={2}>
												<Row justifyContent="space-between">
													<TenantName href={invoice.conversation_url}>
														{invoice.tenant_name}
													</TenantName>
													<IconRelativeWrapper
														className="invoice-tenant-details-icon"
														style={{ marginLeft: 12 }}
													>
														<InfoIcon />
														<InfoPopup>
															<InfoPopupLabel>Listing</InfoPopupLabel>
															<InfoPopupValue>{invoice.listing_title}</InfoPopupValue>
															{invoice.room_title && (
																<>
																	<InfoPopupLabel>Room</InfoPopupLabel>
																	<InfoPopupValue>{invoice.room_title}</InfoPopupValue>
																</>
															)}
														</InfoPopup>
													</IconRelativeWrapper>
												</Row>
											</TableRowColumn>
											<TableRowColumn>
												<Bold>
													{numberFormatService.formatCurrency(
														+invoice.total_amount,
														invoice.currency?.symbol,
														true,
													)}
												</Bold>
											</TableRowColumn>
											<TableRowColumn>
												{dateService.formatForDisplay(invoice.date_due)}
											</TableRowColumn>
											<TableRowColumn flex={0.7}>
												<Row>
													<Bold>
														<span style={{ color: invoiceService.getStatusColor(invoice.status) }}>
															{invoice.status}
														</span>
													</Bold>
													{invoice.status === InvoiceStatus.Overdue && (
														<IconRelativeWrapper>
															<InfoIcon style={{ fill: colors.red, marginLeft: '12px' }} />
															<InfoPopup>
																<InfoPopupWarning>
																	This invoice payment is overdue. Please make payment within 14
																	days, otherwise your listing will be deactivated.
																</InfoPopupWarning>
															</InfoPopup>
														</IconRelativeWrapper>
													)}
													{invoice.status === InvoiceStatus.Draft && (
														<IconRelativeWrapper>
															<InfoIcon style={{ fill: colors.yellow, marginLeft: '12px' }} />
															<InfoPopup>
																<InfoPopupText>
																	This invoice is still being finalized by our finance team.
																</InfoPopupText>
															</InfoPopup>
														</IconRelativeWrapper>
													)}
												</Row>
											</TableRowColumn>
											<TableRowColumn flex={0.8}>
												<Row justifyContent="center">
													{invoice.queried ? (
														<GreyText>Queried</GreyText>
													) : (
														<div>
															<QueryIconContainer>
																<QueryIcon
																	fill={colors.darkBlue}
																	cursor="pointer"
																	onClick={() => handleQueryInvoice(invoice)}
																/>
																<QueryInfoPopup>Query invoice</QueryInfoPopup>
															</QueryIconContainer>
														</div>
													)}
													{Boolean(invoice.invoice_url) && (
														<DocumentIconContainer>
															<Link
																to={{ pathname: invoice.invoice_url }}
																target="_blank"
																rel="noopener noreferrer"
															>
																<DocumentIcon fill={colors.darkBlue} cursor="pointer" />
															</Link>
															<QueryInfoPopup>View invoice</QueryInfoPopup>
														</DocumentIconContainer>
													)}
												</Row>
											</TableRowColumn>
											<TableRowColumn>
												<ViewButton
													className="view-invoice-details-button"
													onClick={() => handleInvoiceClick(invoice)}
												>
													View details
												</ViewButton>
											</TableRowColumn>
										</TableRow>
									))}
									{nextPageUrl && (
										<NextButtonContainer>
											<Button isOutline onClick={handleViewMoreClick}>
												Show more
											</Button>
										</NextButtonContainer>
									)}
								</>
							)}
						</TableRows>
					</TableContainer>
				)}
			</Container>
			{showInvoicesTour && <InvoicesProductTour onComplete={handleTourComplete} />}
		</>
	);
};

export default InvoicesDashboard;

const Container = styled.div`
	flex-direction: column;
	margin-bottom: 40px;
	padding: 16px 0;

	@media (max-width: 500px) {
		padding: 40px 24px;
		padding-right: 0px;
		padding-left: 0px;
		width: 100%;
	}
`;

const EmptyStateHeaderText = styled.p`
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 16px;
`;

const SmallText = styled.p`
	margin-top: 24px;
	margin-bottom: 24px;
	font-size: 14px;
`;

const EmptyStateContainer = styled.div`
	text-align: center;
	max-width: 450px;
	margin: auto;
	padding: 16px;
	padding-top: 0;
`;

const InlineLink = styled.a`
	text-decoration: underline;
	color: ${colors.blue};
`;

const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 16px;
`;

const HeaderSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: fit-content;
`;

const FlexRow = styled.div`
	display: flex;
	align-items: center;
`;

const MobileTitleRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const HelpText = styled.div`
	font-size: 14px;
	color: ${colors.darkTurquoise};
	font-weight: 600;
	margin-left: 16px;
	cursor: pointer;
`;

const MainTitle = styled.p`
	font-size: 32px;
	font-weight: 400;
	margin-top: -16px;
`;

const MainSubTitle = styled.p`
	margin: 0;
	margin-right: 16px;
	font-size: 20px;
	font-weight: 600;
	white-space: nowrap;
`;

const GreyText = styled.div`
	color: ${colors.grey60};
`;

const MobileGreyTextWrapper = styled.span`
	color: ${colors.grey60};
	font-weight: 400;
	font-size: 12px;
`;

const MarginRight = styled.span`
	margin-right: 8px;
`;

const InvoiceTotal = styled.p`
	margin: 0;
	color: ${colors.pink};
	font-size: 24px;
	font-weight: 600;
	margin-right: 24px;
	white-space: nowrap;
`;

const FilterBarContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const SearchIcon = styled(SearchSvg)`
	fill: ${colors.darkBlue};
	width: 24px;
	margin-right: 8px;
`;

const FilterChipsContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
	margin-right: 48px;
`;

const FilterChipContainer = styled.div<{ active: boolean }>`
	font-size: 14px;
	font-weight: 600;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 30px;
	padding: 0 12px;
	cursor: pointer;
	user-select: none;
	border: 1px solid ${colors.darkBlue};
	color: ${colors.darkBlue};
	transition: all 100ms;
	box-sizing: border-box;

	${({ active }) =>
		active &&
		`
			color: ${colors.white}
			background: ${colors.darkBlue};
			border-color: ${colors.darkBlue};
		`}
`;

const ButtonContainer = styled.div`
	min-width: 104px;
`;

const TableContainer = styled.div`
	width: 100%;
	margin-top: 24px;
`;

const TableHeaders = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const TableHeaderItem = styled.div<{ flex?: number }>`
	font-size: 12px;
	flex: ${({ flex }) => (flex ? flex : 1)};
`;

const TableRows = styled.div`
	margin-top: 8px;
`;

const InfoPopup = styled.div`
	position: absolute;
	padding: 8px;
	border-radius: 12px;
	background: ${colors.white};
	left: 0;
	top: 40px;
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
	color: ${colors.darkBlue};
	line-height: 150%;
	z-index: 1;
	min-width: 200px;
	visibility: hidden;
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
`;

const QueryInfoPopup = styled(InfoPopup)`
	left: 0;
	top: 32px;
	min-width: unset;
	width: auto;
	white-space: nowrap;
`;

const QueryIconContainer = styled.div`
	position: relative;
	width: 24px;
	align-items: center;
	display: flex;

	&:hover ${QueryInfoPopup} {
		visibility: visible;
	}
`;

const DocumentIconContainer = styled.div`
	position: relative;
	width: 24px;

	&:hover ${QueryInfoPopup} {
		visibility: visible;
	}
`;

const QueryIcon = styled(QuestionSvg)`
	visibility: hidden;
`;

const DocumentIcon = styled(DocumentSvg)`
	visibility: hidden;
	margin-left: 8px;
	margin-right: 8px;
`;

const TableRow = styled.div`
	display: flex;
	align-items: center;
	padding: 16px 0;
	border-bottom: 1px solid ${colors.grey10};

	&:hover ${QueryIcon} {
		visibility: visible;
	}

	&:hover ${DocumentIcon} {
		visibility: visible;
	}
`;

const TableRowColumn = styled.div<{ flex?: number }>`
	flex: ${({ flex }) => (flex ? flex : 1)};
	font-size: 14px;
	color: ${colors.darkBlue};
`;

const ViewButton = styled.div`
	font-weight: 600;
	font-size: 14px;
	width: fit-content;
	cursor: pointer;
`;

const Bold = styled.div`
	font-weight: 600;
`;

const Row = styled.div<{ fullWidth?: boolean; justifyContent?: string }>`
	display: flex;
	align-items: center;
	${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
	${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent}` : '')}
`;

const TenantName = styled.a`
	color: ${colors.darkBlue};
	text-overflow: ellipsis;
	&:hover {
		text-decoration: underline;
		color: ${colors.darkTurquoise};
	}
`;

const InfoPopupLabel = styled.div`
	color: ${colors.grey60};
`;

const InfoPopupValue = styled.div`
	color: ${colors.darkBlue};
	margin-bottom: 8px;
`;

const InfoIcon = styled(InfoCircle)`
	width: 16px;
	fill: ${colors.grey60};
	cursor: pointer;

	&:hover + ${InfoPopup} {
		visibility: visible;
	}
`;

const IconRelativeWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	margin-left: 12px;
	margin-right: 12px;
`;

const InfoPopupWarning = styled.div`
	color: ${colors.red};
`;

const InfoPopupText = styled.div`
	color: ${colors.grey60};
`;

const ContainerMobile = styled.div`
	padding: 24px 0;
	margin-bottom: 40px;
`;

const MobileInvoiceContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${colors.grey10};
	padding: 12px 16px;
	cursor: pointer;
`;

const MobileBoldText = styled.p`
	font-size: 14px;
	margin-bottom: 8px;
	font-weight: 600;
`;

const MobileStatusText = styled.p`
	font-size: 14px;
	margin: 0;
`;

const MobileInvoiceItemContent = styled.div`
	flex: 1;
`;

const MobileTenantName = styled.a`
	color: ${colors.darkBlue};
	font-size: 14px;
	text-decoration: underline;
`;

const MobileIconRight = styled(ChevronRight)`
	margin: 16px;
	margin-left: 24px;
	margin-right: 8px;
	cursor: pointer;
`;

const MobileTopSection = styled.div`
	padding: 0 24px;
	width: 100%;
`;

const MobileChipsContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
	padding-bottom: 16px;
	overflow-y: auto;
	padding-top: 16px;
`;

const PaddingBottom = styled.div`
	padding-bottom: 16px;
	width: 100%;
`;

const LoaderContainer = styled.div`
	margin: auto;
	margin-top: 16px;
`;

const SubTitleText = styled.p`
	margin: 0;
	padding: 0;
	font-size: 16px;
`;

const SubTitleTextMobile = styled(SubTitleText)`
	font-size: 14px;
	margin-bottom: 4px;
`;

const EmptyStateText = styled.div`
	color: ${colors.grey60};
	text-align: center;
	padding: 24px;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		margin-top: 60px;
	}
`;

const NextButtonContainer = styled.div`
	margin: auto;
	margin-top: 8px;
	padding: 24px;
	max-width: 280px;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		margin: auto;
		margin-top: 32px;
		padding: 0;
		max-width: unset;
	}
`;
