import React from 'react';

import api from 'api';
import styled from 'styled-components';

import theme from 'config/theme';
import { Modal } from 'lib/Modal';
import Button from 'lib/Button';
import { SimpleRoom } from 'models/property';
import { TopBar } from 'lib/TopBar';
import { notifyFailure, notifySuccess } from 'lib/Notifications';

const { colors, fontWeights } = theme;

interface DeleteModalProps {
	uuid: string;
	rooms: SimpleRoom[];
	placesAvailable: number;
	roomsAvailable: number;
	onSave(): void;
	onClose(): void;
}

export const DeleteModal = ({
	uuid,
	onSave,
	onClose,
}: DeleteModalProps) => {
	const handleSave = () => {
		api.property
			.deleteProperty(uuid)
			.then(() => {
				notifySuccess('Listing deleted');
				onSave();
				onClose();
			})
			.catch(e => {
				notifyFailure('Error deleting listing. Please try again');
				console.log(e);
			});
	};

	return (
		<Modal width={498}>
			<TopBar title="Delete listing" closeOnRight onClose={() => onClose()} />
			<SubTitle>This property will be removed from your DigsConnect account.</SubTitle>
			<SubTitle isRed>
				Deleting will cancel all current active and pending enquiries to this listing
			</SubTitle>
			<ModalActionBar>
				<ActivateButtonContainer>
					<Button isOutline maxWidth={162} onClick={onClose}>
						Cancel
					</Button>
				</ActivateButtonContainer>
				<ButtonContainer>
					<Button isFullWidth isRed onClick={handleSave}>
						Delete
					</Button>
				</ButtonContainer>
			</ModalActionBar>
		</Modal>
	);
};

export default DeleteModal;

const ButtonContainer = styled.div`
	min-width: 150px;
`;

const ActivateButtonContainer = styled.div`
	margin-right: 16px;
	min-width: 150px;
`;

const ModalActionBar = styled.div`
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 24px;

	@media (max-width: 768px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: ${colors.white};
	}
`;

const SubTitle = styled.div<{ isRed?: boolean }>`
	text-align: center;
	font-weight: ${fontWeights.thin};
	font-size: 16px;
	line-height: 20px;
	padding-right: 32px;
	padding-left: 32px;
	margin-top: 24px;
	color: ${({ isRed }) => (isRed ? `${colors.red}` : `${colors.darkBlue}`)};
	font-weight: ${({ isRed }) => (isRed ? '600' : `${fontWeights.thin}`)};
`;
