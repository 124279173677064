import React, { useState, useEffect } from 'react';

import { PageMeta } from 'constants/page_meta';
import styled from 'styled-components';
import { Loader } from 'lib/Loader';
import theme from 'config/theme';
import LandlordPerformance from './LandlordPerformance';
import Divider from 'lib/Divider';
import api from 'api';
import userContainer from 'containers/user';
import { Subscribe } from 'unstated';
import { LandlordStatistics } from './LandlordStatistics';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import LandlordCharts from './LandlordCharts';
import LandlordPlacements from './LandlordPlacements';
import { ChartConstants, LandlordChartData } from './LandlordChartTypes';
import { InvoicesResponse } from 'models/invoices';
import { ConversationStatus, PaginatedInbox } from 'models/inbox';
import { Notifications } from './types';
import LandlordNotifications from './LandlordNotifications';

const { fontWeights } = theme;
const meta = PageMeta.getStatic('landlord_dashboard');
const LandlordDashboard = () => {
	const [activeEnquiries, setActiveEnquiries] = useState<LandlordChartData>();
	const [potentialPlacements, setPotentialPlacements] = useState<LandlordChartData>();
	const [potentialRevenue, setPotentialRevenue] = useState<LandlordChartData>();
	const [pendingInbox, setPendingInbox] = useState<PaginatedInbox>();
	const [recentPlacements, setRecentPlacements] = useState<InvoicesResponse>();
	const [notifications, setNotifications] = useState<Notifications>();
	const [stats, setStats] = useState<LandlordStatistics>();

	useEffect(() => {
		Promise.all([
			api.userV2.getLandlordGraph(ChartConstants.ActiveEnquiries),
			api.userV2.getLandlordGraph(ChartConstants.PotentialPlacements),
			api.userV2.getLandlordGraph(ChartConstants.PotentialRevenue),
			api.userV2.getNotifications(),
		]).then(([activeResponse, placementResponse, revenueResponse, notificationResponse]) => {
			setActiveEnquiries(activeResponse.data);
			setPotentialPlacements(placementResponse.data);
			setPotentialRevenue(revenueResponse.data);
			setNotifications(notificationResponse.data);
		});

		api.userV2.getLandlordStats().then(res => {
			const statsRes: LandlordStatistics = res.data;
			setStats(statsRes);
		});
		api.inbox.getLandlordInbox(false, '', false, 1, [], 4, ConversationStatus.Pending).then(res => {
			const response: PaginatedInbox = res.data;
			setPendingInbox(response);
		});
		api.invoicing.getInvoices().then(res => {
			const invoiceResponse: InvoicesResponse = res.data;
			setRecentPlacements(invoiceResponse);
		});
	}, []);

	return (
		<>
			<Container>
				<Subscribe to={[userContainer]}>
					{({ state }) => {
						return (
							<>
								<TitleContainer>
									Hello {state.v2.first_name}, Welcome to your landlord dashboard
								</TitleContainer>
							</>
						);
					}}
				</Subscribe>
				{notifications && notifications.notifications.length > 0 && (
					<LandlordNotifications notifications={notifications} />
				)}
				{stats && <LandlordPerformance stats={stats} />}
				<Divider noMargin />
				{activeEnquiries && potentialPlacements && potentialRevenue && (
					<LandlordCharts enquiryData={[activeEnquiries, potentialPlacements, potentialRevenue]} />
				)}
				<Divider noMargin />
				{pendingInbox && recentPlacements && (
					<LandlordPlacements conversations={pendingInbox} invoices={recentPlacements} />
				)}
			</Container>
		</>
	);
};

export default LandlordDashboard;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const TitleContainer = styled.div`
	font-size: 32px;
	padding-top: 16px;
	font-weight: ${fontWeights.bold};

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		padding: 16px;
	}
`;
