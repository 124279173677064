export interface LandlordChartData {
	colours: string[];
	labels: string[];
	total: number;
	values: number[];
}
export enum ChartPopupText {
	ActiveEnquiries = 'The number of pending and connected enquiries in your inbox.',
	PotentialPlacements = 'The number of placements you have made in relation to the number of placements you could still make, based on your current (active) enquiries.',
	PotentialRevenue = 'The revenue you have generated in rent, on our platform, in relation to the potential revenue you could still earn, based on your current (active) enquiries.',
}

export enum ChartTitles {
	ActiveEnquiries = 'Active Enquiries',
	PotentialPlacements = 'Potential Placements',
	PotentialRevenue = 'Potential Revenue',
}

export enum ChartConstants {
	ActiveEnquiries = '1',
	PotentialPlacements = '2',
	PotentialRevenue = '3',
}
