import React, { useState } from 'react';

import api from 'api';
import styled from 'styled-components';

import theme from 'config/theme';
import Button from 'lib/Button';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { SimpleRoom } from 'models/property';
import { TopBar } from 'lib/TopBar';

import { LeaseType } from 'components/add-digs/types';
import { notifySuccess, notifyFailure, notifyError } from 'lib/Notifications';

const { colors, fontWeights, fontSizes } = theme;

interface ActivateModalProps {
	uuid: string;
	title: string;
	subType: string;
	leaseType: LeaseType;
	rooms: SimpleRoom[];
	placesAvailable: number;
	roomsAvailable: number;
	isMobile: boolean;
	bottomSheet: boolean;
	onClose(): void;
	onAvailabilityOpen(): void;
	onSave(): void;
}

const ActivateModal = ({
	uuid,
	rooms,
	placesAvailable,
	roomsAvailable,
	bottomSheet,
	leaseType,
	onClose,
	onAvailabilityOpen,
	onSave,
}: ActivateModalProps) => {
	const [availablePlaces, setAvailablePlaces] = useState(placesAvailable ?? 0);
	const [availableRooms, setAvailableRooms] = useState(roomsAvailable ?? 0);
	const [isLoading, setIsLoading] = useState(false);

	const handleSave = () => {
		if (leaseType !== LeaseType.EntirePlace && (availablePlaces === 0 || availableRooms === 0)) {
			notifyError(
				'No rooms active or places available. Update this listings availability before making it live',
			);
			return;
		}

		setIsLoading(true);
		api.property
			.updateSearchable(uuid)
			.then(res => {
				setIsLoading(false);
				if (!res.data.searchable) {
					if (res.data.errors?.length) {
						notifyFailure(`Error activating listing. ${res.data.errors[0].error}`);
						return;
					}
					notifyFailure('Error activating listing. Please try again.');
					return;
				}

				notifySuccess('Your listing is now live');
				onSave();
				onClose();
			})
			.catch(e => {
				console.log(e);
				setIsLoading(false);
				notifyFailure('Error activating listing. Please try again.');
			});
	};

	return (
		<>
			{!bottomSheet ? (
				<>
					<TopBar title="Activate listing" closeOnRight onClose={() => onClose()} />
					<SubTitle>
						Is the below capacity accurate for this listing before it goes live? If not, please
						update to make sure that tenants know what's available.
					</SubTitle>
					<AvailableContent>
						<CapacityContainer>
							<NumAvailable>{availablePlaces}</NumAvailable>
							<NumAvailableText>Places available</NumAvailableText>
						</CapacityContainer>
					</AvailableContent>
					<ModalActionBar>
						{leaseType === LeaseType.RoomByRoom && (
							<ButtonContainer>
								<Button isOutline maxWidth={162} onClick={onAvailabilityOpen}>
									Update availability
								</Button>
							</ButtonContainer>
						)}
						<ActivateButtonContainer>
							<Button isFullWidth onClick={handleSave} isLoading={isLoading}>
								Activate
							</Button>
						</ActivateButtonContainer>
					</ModalActionBar>
				</>
			) : (
				<BottomSheetContainer>
					<TopBar title="Activate listing" closeOnRight onClose={() => onClose()} />
					<SubTitle>
						Is the below capacity accurate for this listing before it goes live? If not, please
						update to make sure that tenants know what's available.
					</SubTitle>
					<AvailableContent>
						<CapacityContainer>
							<NumAvailable>{availablePlaces}</NumAvailable>
							<NumAvailableText>Places available</NumAvailableText>
						</CapacityContainer>
					</AvailableContent>
					<ModalActionBar>
						{leaseType === LeaseType.RoomByRoom && (
							<ButtonContainer>
								<Button isOutline maxWidth={162} onClick={onAvailabilityOpen}>
									Update availability
								</Button>
							</ButtonContainer>
						)}
						<ActivateButtonContainer>
							<Button isFullWidth onClick={handleSave} isLoading={isLoading}>
								Activate
							</Button>
						</ActivateButtonContainer>
					</ModalActionBar>
				</BottomSheetContainer>
			)}
		</>
	);
};

export default ActivateModal;

const BottomSheetContainer = styled.div``;

const AvailableContent = styled.div`
	display: flex;
	padding: 15px 48px;
	padding-bottom: 42px;
	padding-top: 32px;
	overflow-y: auto;
	align-items: center;
	justify-content: center;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding: 24px;
	}
`;

const ButtonContainer = styled.div``;

const ActivateButtonContainer = styled.div`
	margin-left: 16px;
	min-width: 162px;
`;

const CapacityContainer = styled.div`
	margin-left: 16px;
	text-align: center;
`;

const NumAvailable = styled.div`
	font-size: ${fontSizes.xlarge};
	text-transform: capitalize;
`;
const NumAvailableText = styled.div`
	margin-top: 10px;
	font-size: ${fontSizes.small};
`;

const Divider = styled.div`
	border-right: 1px solid ${colors.grey10};
	height: 60px;
	margin-left: 16px;
`;

const ModalActionBar = styled.div`
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 24px;
	padding-top: 0px;

	@media (max-width: 768px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: white;
	}
`;

const SubTitle = styled.div`
	display: flex-start;
	font-weight: ${fontWeights.thin};
	font-size: 16px;
	line-height: 20px;
	margin-top: 16px;
	padding-right: 24px;
	padding-left: 24px;
`;
