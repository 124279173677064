import React from 'react';

import styled from 'styled-components';
import theme from 'config/theme';
import { ReactComponent as InfoIcon } from 'assets/images/landlord-dashboard/InfoIcon.svg';

const { colors } = theme;
interface InfoPopupProps {
	onHoverText?: String;
	onClick?: () => void;
}

const InfoIconPopup = ({ onHoverText }: InfoPopupProps) => {
	return (
		<Container>
			<InfoIcon />
			<InfoPopupContainer>{onHoverText}</InfoPopupContainer>
		</Container>
	);
};

export default InfoIconPopup;

const InfoPopup = styled.div`
	position: absolute;
	padding: 8px;
	border-radius: 12px;
	background: ${colors.white};
	right: 0;
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
	color: ${colors.darkBlue};
	line-height: 150%;
	z-index: 1;
	min-width: 200px;
	visibility: hidden;
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
`;

const InfoPopupContainer = styled(InfoPopup)`
	display: flex;
	flex-wrap: wrap;
	top: 24px;
	width: auto;
	z-index: 2;
`;

const Container = styled.div`
	position: relative;
	&:hover ${InfoPopupContainer} {
		visibility: visible;
	}
`;
