import theme from 'config/theme';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

const { colors } = theme;

const ToggleContainer = styled.div`
	display: block;
	padding-bottom: 3px;
	width: 60px;
	height: 40px;
	position: relative;
`;

const ToggleInput = styled.input`
	display: none;
`;

const ToggleLabel = styled.label`
	cursor: pointer;
	position: relative;
	height: 40px;
	background-color: ${colors.darkTurquoise};

	::before {
		background: ${colors.darkGray};
		border-radius: 25px;
		border: 3px solid ${colors.darkGray};
		content: '';
		height: 30px;
		position: absolute;
		opacity: 1;
		transition: all 0.4s ease-in-out;
		width: 60px;
	}

	::after {
		background: ${colors.white};
		border-radius: 20px;
		content: '';
		height: 20px;
		left: 6px;
		margin-top: 6px;
		position: absolute;
		top: -1px;
		transition: all 0.3s ease-in-out;
		width: 20px;
	}

	/* stylelint-disable-next-line selector-type-no-unknown */
	${ToggleInput}:checked + &::before {
		border-color: ${colors.darkTurquoise};
		background-color: ${colors.darkTurquoise};
	}

	/* stylelint-disable-next-line selector-type-no-unknown */
	${ToggleInput}:checked + &::after {
		background: ${colors.white};
		left: 34px;
	}
`;

class Toggle extends PureComponent {
	static defaultProps = {
		checked: false,
		inputStyle: {},
	};

	render() {
		const { id, onBlur, onChange, inputStyle, checked, ...otherProps } = this.props;

		return (
			<ToggleContainer {...otherProps}>
				<ToggleInput
					style={inputStyle}
					checked={checked}
					onChange={onChange}
					onBlur={onBlur}
					id={id}
					type="checkbox"
				/>
				<ToggleLabel htmlFor={this.props.id} />
			</ToggleContainer>
		);
	}
}

export default Toggle;
