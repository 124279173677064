export interface ProductTourStep {
	elementClass: string;
	title: string;
	description: string;
}

export const invoicesProductTourSteps: ProductTourStep[] = [
	{
		elementClass: 'invoices-tab',
		title: 'Welcome to the Invoices page!',
		description:
			'Here you can find all invoices for the amounts owed to DigsConnect for placements.',
	},
	{
		elementClass: 'filter-paid',
		title: 'Filters and search',
		description:
			'Filters allow you to show only the invoices which match your selected status. You can also search for invoices by tenant name or property name using the search bar.',
	},
	{
		elementClass: 'invoices-total',
		title: 'Total outstanding and pay now',
		description:
			'Here you can find the total outstanding amount owed to DigsConnect. Clicking ‘Pay now’ will show the DigsConnect payment options.',
	},
	{
		elementClass: 'invoice-tenant-details-icon',
		title: 'Tenant details',
		description:
			'You can view the details about the placed tenant by hovering over the ‘i’ icon, or clicking their name to view their inbox conversation.',
	},
	{
		elementClass: 'view-invoice-details-button',
		title: 'View details',
		description:
			'Clicking on the ‘View details’ button will take you to a detailed breakdown of the selected invoice, including tenant and lease details.',
	},
];

export const manageListingProductTourSteps: ProductTourStep[] = [
	{
		elementClass: 'listing-card',
		title: 'Redesigned listing cards',
		description:
			'Listing cards show a quick overview of active rooms, and capacity. Click anywhere on a listing card to expand more options.',
	},
	{
		elementClass: 'lease-type-container',
		title: 'Listing card details',
		description:
			'Clicking on any listing card will expand more options for that selected listing. Here you can see details about the listing, and quick actions.',
	},
	{
		elementClass: 'availability-button',
		title: 'Update availability',
		description:
			'For room-by-room leases, you can select ‘Update availability’ to set your rooms as ‘available’ or ‘sold out’, and how many spaces are still available.',
	},
	{
		elementClass: 'edit-button',
		title: 'Edit listing',
		description:
			'You can select ‘Edit’ in order to change anything about your listing. This includes things like room details, features, pictures and pricing.',
	},
	{
		elementClass: 'deactivate-button',
		title: 'Activate or Deactivate listing',
		description:
			'You can ‘activate’ to make a listing live and searchable, or ‘deactivate’ a live listing to remove it from being seen by potential tenants.',
	},
	{
		elementClass: 'title-container',
		title: 'Preview active listings',
		description:
			'Click here on active listings to go to your listing page.This is what potential tenants will see when they view your listing.',
	},
];

export const manageListingProductTourStepsEntirePlace: ProductTourStep[] = [
	{
		elementClass: 'listing-card',
		title: 'Redesigned listing cards',
		description:
			'Listing cards show a quick overview of active rooms, and capacity. Click anywhere on a listing card to expand more options.',
	},
	{
		elementClass: 'lease-type-container',
		title: 'Listing card details',
		description:
			'Clicking on any listing card will expand more options for that selected listing. Here you can see details about the listing, and quick actions.',
	},
	{
		elementClass: 'edit-button',
		title: 'Edit listing',
		description:
			'You can select ‘Edit listing’ in order to change anything about your listing. This includes things like room details, features, pictures and pricing.',
	},
	{
		elementClass: 'deactivate-button',
		title: 'Activate or Deactivate listing',
		description:
			'You can ‘activate’ to make a listing live and searchable, or ‘deactivate’ a live listing to remove it from being seen by potential tenants.',
	},
	{
		elementClass: 'title-container',
		title: 'Preview active listings',
		description:
			'Click here on active listings to go to your listing page.This is what potential tenants will see when they view your listing.',
	},
];
