import React from 'react';

interface IconProps {
	active: boolean;
}

const DashboardIcon = ({ active }: IconProps) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		{active ? (
			<>
				<mask id="path-1-inside-1_8351_28062" fill="white">
					<rect x="3" y="3" width="8" height="8" rx="1" />
				</mask>
				<rect
					x="3"
					y="3"
					width="8"
					height="8"
					rx="1"
					stroke="#041F47"
					stroke-width="4"
					mask="url(#path-1-inside-1_8351_28062)"
				/>
				<mask id="path-2-inside-2_8351_28062" fill="white">
					<rect x="3" y="13" width="8" height="8" rx="1" />
				</mask>
				<rect
					x="3"
					y="13"
					width="8"
					height="8"
					rx="1"
					stroke="#041F47"
					stroke-width="4"
					mask="url(#path-2-inside-2_8351_28062)"
				/>
				<mask id="path-3-inside-3_8351_28062" fill="white">
					<rect x="13" y="3" width="8" height="8" rx="1" />
				</mask>
				<rect
					x="13"
					y="3"
					width="8"
					height="8"
					rx="1"
					stroke="#041F47"
					stroke-width="4"
					mask="url(#path-3-inside-3_8351_28062)"
				/>
				<mask id="path-4-inside-4_8351_28062" fill="white">
					<rect x="13" y="13" width="8" height="8" rx="1" />
				</mask>
				<rect
					x="13"
					y="13"
					width="8"
					height="8"
					rx="1"
					stroke="#041F47"
					stroke-width="4"
					mask="url(#path-4-inside-4_8351_28062)"
				/>
			</>
		) : (
			<>
				<mask id="path-1-inside-1_8351_28062" fill="white">
					<rect x="3" y="3" width="8" height="8" rx="1" />
				</mask>
				<rect
					x="3"
					y="3"
					width="8"
					height="8"
					rx="1"
					stroke="#CDD2DA"
					stroke-width="4"
					mask="url(#path-1-inside-1_8351_28062)"
				/>
				<mask id="path-2-inside-2_8351_28062" fill="white">
					<rect x="3" y="13" width="8" height="8" rx="1" />
				</mask>
				<rect
					x="3"
					y="13"
					width="8"
					height="8"
					rx="1"
					stroke="#CDD2DA"
					stroke-width="4"
					mask="url(#path-2-inside-2_8351_28062)"
				/>
				<mask id="path-3-inside-3_8351_28062" fill="white">
					<rect x="13" y="3" width="8" height="8" rx="1" />
				</mask>
				<rect
					x="13"
					y="3"
					width="8"
					height="8"
					rx="1"
					stroke="#CDD2DA"
					stroke-width="4"
					mask="url(#path-3-inside-3_8351_28062)"
				/>
				<mask id="path-4-inside-4_8351_28062" fill="white">
					<rect x="13" y="13" width="8" height="8" rx="1" />
				</mask>
				<rect
					x="13"
					y="13"
					width="8"
					height="8"
					rx="1"
					stroke="#CDD2DA"
					stroke-width="4"
					mask="url(#path-4-inside-4_8351_28062)"
				/>
			</>
		)}
	</svg>
);

export default DashboardIcon;
