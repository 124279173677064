import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { TopBar } from 'lib/TopBar';
import { Invoice } from 'models/invoices';
import Button from 'lib/Button';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { Select, SelectOption } from 'lib/Select';
import api from 'api';
import { notifyFailure, notifySuccess } from 'lib/Notifications';

const { colors, fonts } = theme;

const reasonOptions: SelectOption[] = [
	'This tenant was not placed',
	'The value is incorrect',
	'I need an extention on this payment',
	'I am still waiting for the tenant to pay',
	'Other',
].map(item => ({
	label: item,
	value: item,
}));

interface QueryInvoiceModalContentProps {
	invoice: Invoice;
	onClose(): void;
	onQueried(): void;
}

const QueryInvoiceModalContent = ({
	invoice,
	onClose,
	onQueried,
}: QueryInvoiceModalContentProps) => {
	const [reason, setReason] = useState('');
	const [description, setDescription] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		if (isLoading) {
			return;
		}

		if (!reason || description.trim().length > 500) {
			return;
		}

		setIsLoading(true);
		const response = await api.invoicing.postDispute(invoice.uuid, reason, description.trim());
		setIsLoading(false);

		if (response.status >= 200 && response.status <= 204) {
			notifySuccess('Query submitted successfully');
			onQueried();
			return;
		}

		notifyFailure('Failed to query invoice. Please try again later');
	};

	return (
		<>
			<TopBar title="Query invoice" closeOnRight onClose={onClose} />
			<Container>
				<PageSubTitle>What is the reason you are wanting to query this invoice?</PageSubTitle>
				<LabelText>Please select a reason from the dropdown below:</LabelText>
				<Select
					placeholder={'Select'}
					options={reasonOptions}
					selectedOption={reasonOptions.find(option => option.value === reason)}
					onChange={option => setReason(option.value)}
				/>
				<LabelText>Want to tell us in more detail? (Optional)</LabelText>
				<TextAreaWraper>
					<TextArea
						value={description}
						placeholder="Description"
						onInput={(e: any) => {
							setDescription(e.target.value);
						}}
					></TextArea>
					<TextAreaCount>{description.trim().length}/500</TextAreaCount>
				</TextAreaWraper>
			</Container>
			<ModalActionBar>
				<Button
					isLoading={isLoading}
					isDisabled={!reason || description.trim().length > 500}
					onClick={handleSubmit}
				>
					Submit query
				</Button>
			</ModalActionBar>
		</>
	);
};

export default QueryInvoiceModalContent;

const PageSubTitle = styled.div`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 24px;
`;

const LabelText = styled.div`
	font-size: 16px;
	margin-top: 24px;
	margin-bottom: 24px;
`;

const Container = styled.div`
	padding: 24px;
	overflow: auto;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding-bottom: 100px;
	}
`;

const ModalActionBar = styled.div`
	flex-shrink: 0;
	border-top: 1px solid ${colors.grey10};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 24px;
	padding-top: 16px;
	gap: 16px;

	@media (max-width: 768px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: ${colors.white};
		min-width: 320px;
	}
`;

const TextArea = styled.textarea`
	font-size: 16px;
	color: ${colors.darkBlue};
	background-color: ${colors.grey05};
	padding: 20px;
	z-index: 0;
	border-radius: 20px;
	outline: none;
	height: 180px;
	width: 100%;
	font-family: ${fonts.Gilroy};
`;

const TextAreaWraper = styled.div`
	position: relative;
`;

const TextAreaCount = styled.div`
	position: absolute;
	right: 10px;
	bottom: 10px;
	color: ${colors.grey60};
`;
