import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { TopBar } from 'lib/TopBar';
import api from 'api';
import { Invoice, InvoiceDetail, InvoiceStatus } from 'models/invoices';
import dateService from 'services/dateService';
import Divider from 'lib/Divider';
import numberFormatService from 'services/numberFormatService';
import invoiceService from './invoiceService';
import Button from 'lib/Button';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as OpenNewTabIcon } from 'assets/icons/open-tab.svg';
import { notifySuccess } from 'lib/Notifications';
import ThreeDotsLoader from 'lib/ThreeDotsLoader';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { featureFlagContainer, FeatureFlag } from 'containers/featureFlags';

const { colors } = theme;

interface InvoiceDetailsModalContentProps {
	invoice: Invoice;
	onClose(): void;
	onQueryClick(): void;
}

const InvoiceDetailsModalContent = ({
	invoice,
	onClose,
	onQueryClick,
}: InvoiceDetailsModalContentProps) => {
	const [details, setDetails] = useState<InvoiceDetail>();
	useEffect(() => {
		if (!invoice) {
			return;
		}
		api.invoicing.getInvoice(invoice.uuid).then(response => {
			setDetails(response.data);
		});
	}, [invoice]);

	const handleCopyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text);
		notifySuccess('Copied to clipboard!');
	};

	return (
		<>
			<TopBar
				title={invoice.invoice_number || `${invoice.status} invoice`}
				subTitle={dateService.formatForDisplay(invoice.date_invoiced)}
				closeOnRight
				onClose={onClose}
			/>
			{(!invoice || !details) && (
				<LoaderContainer>
					<ThreeDotsLoader />
				</LoaderContainer>
			)}
			{invoice && details && (
				<>
					<Container>
						<SectionTitle>Invoice breakdown</SectionTitle>
						<LineItem>
							<Label>Tenant name</Label>
							<Value>{invoice.tenant_name}</Value>
						</LineItem>
						<LineItem>
							<Label>Rental amount</Label>
							<Value>
								{numberFormatService.formatCurrency(
									+(details.rental_amount || 0),
									details.currency.symbol,
									true,
								)}
							</Value>
						</LineItem>
						<LineItem>
							<Label>Lease term</Label>
							<Value>{details.lease_term}</Value>
						</LineItem>
						<Divider />
						<LineItem>
							<Label>Lease value</Label>
							<Value>
								{numberFormatService.formatCurrency(
									+(details.total_lease_amount || 0),
									details.currency.symbol,
									true,
								)}
							</Value>
						</LineItem>
						<LineItem>
							<Label>3.5% fee</Label>
							<Value>
								{numberFormatService.formatCurrency(
									+(details.base_fee_amount || 0),
									details.currency.symbol,
									true,
								)}
							</Value>
						</LineItem>
						{featureFlagContainer.isEnabled(FeatureFlag.InvoiceDiscount) && (
							<>
								{details.discount_amount && (
									<LineItem>
										<Label>{details.discount_rate_readable}</Label>
										<Value>
											-{' '}
											{numberFormatService.formatCurrency(
												+(details.discount_amount || 0),
												details.currency.symbol,
												true,
											)}
										</Value>
									</LineItem>
								)}
							</>
						)}
						{featureFlagContainer.isEnabled(FeatureFlag.InvoiceDiscount) && (
							<>
								<LineItem>
									<Label>Sub-total</Label>
									<Value>
										{numberFormatService.formatCurrency(
											+(details.subtotal_amount || 0),
											details.currency.symbol,
											true,
										)}
									</Value>
								</LineItem>
							</>
						)}
						<LineItem>
							<Label>15% VAT</Label>
							<Value>
								{numberFormatService.formatCurrency(
									+(details.vat_amount || 0),
									details.currency.symbol,
									true,
								)}
							</Value>
						</LineItem>
						<LineItem>
							<Label>Total</Label>
							<Value>
								{numberFormatService.formatCurrency(
									+details.total_amount,
									details.currency.symbol,
									true,
								)}
							</Value>
						</LineItem>
						{invoice.status !== InvoiceStatus.Draft && (
							<>
								<Divider />
								<LineItem>
									<Label>Paid amount</Label>
									<Value>
										{numberFormatService.formatCurrency(
											+(details.paid_amount || 0),
											details.currency.symbol,
											true,
										)}
									</Value>
								</LineItem>
								<LineItem>
									<Label>Outstanding amount</Label>
									<Value>
										{numberFormatService.formatCurrency(
											+(details.outstanding_amount || 0),
											details.currency.symbol,
											true,
										)}
									</Value>
								</LineItem>
								<LineItem>
									<Label>Due date</Label>
									<Value>{dateService.formatForDisplay(details.date_due)}</Value>
								</LineItem>
								<LineItem>
									<Label>Invoice no.</Label>
									<Value>{details.invoice_number}</Value>
								</LineItem>
								<LineItem>
									<Label>Status</Label>
									<Value style={{ color: invoiceService.getStatusColor(details.status) }}>
										<b>{details.status}</b>
									</Value>
								</LineItem>
								<Divider />
								<WarningText>Please make payments by EFT to the below bank details:</WarningText>
								<LineItem>
									<BankDetailsLabel>Account name:</BankDetailsLabel>
									<BankDetailsValue>{invoiceService.getBankDetails().accountName}</BankDetailsValue>
								</LineItem>
								<LineItem>
									<BankDetailsLabel>Bank:</BankDetailsLabel>
									<BankDetailsValue>{invoiceService.getBankDetails().bankShort}</BankDetailsValue>
								</LineItem>
								<LineItem>
									<BankDetailsLabel>Acc no:</BankDetailsLabel>
									<CopyContentContainer
										onClick={() =>
											handleCopyToClipboard(invoiceService.getBankDetails().accountNumber)
										}
									>
										<BankDetailsValue>
											{invoiceService.getBankDetails().accountNumber}
										</BankDetailsValue>
										<CopyIcon style={{ marginLeft: 16 }} />
									</CopyContentContainer>
								</LineItem>
								<LineItem>
									<BankDetailsLabel>Branch code:</BankDetailsLabel>
									<CopyContentContainer
										onClick={() =>
											handleCopyToClipboard(invoiceService.getBankDetails().branchCode)
										}
									>
										<BankDetailsValue>
											{invoiceService.getBankDetails().branchCode}
										</BankDetailsValue>
										<CopyIcon style={{ marginLeft: 16 }} />
									</CopyContentContainer>
								</LineItem>
								<LineItem>
									<BankDetailsLabel>Ref no:</BankDetailsLabel>
									<CopyContentContainer
										onClick={() => handleCopyToClipboard(details.invoice_number)}
									>
										<BankDetailsValue>{details.invoice_number}</BankDetailsValue>
										<CopyIcon style={{ marginLeft: 16 }} />
									</CopyContentContainer>
								</LineItem>
							</>
						)}
						{invoice.status === InvoiceStatus.Draft && (
							<>
								<Divider />
								<Text>
									This invoice is still being finalized by our finance team. The final amount &
									payment due date will appear shortly. If any current details are incorrect, please
									query below and notify us about your concern.
								</Text>
							</>
						)}
					</Container>
					<ModalActionBar>
						<Button
							isDisabled={!details.invoice_url || invoice.status === InvoiceStatus.Draft}
							isOutline
							link={details.invoice_url}
							isExternalLink
							openLinkInNewTab
						>
							<ButtonContent>
								<span>View invoice</span>
								<OpenNewTabIcon
									style={{
										marginLeft: 16,
										fill:
											!details.invoice_url || invoice.status === InvoiceStatus.Draft
												? colors.grey20
												: colors.darkBlue,
									}}
								/>
							</ButtonContent>
						</Button>
						<Button isDisabled={Boolean(invoice.queried)} isOutline onClick={onQueryClick}>
							Query invoice
						</Button>
					</ModalActionBar>
				</>
			)}
		</>
	);
};

export default InvoiceDetailsModalContent;

const SectionTitle = styled.div`
	font-size: 16px;
`;

const Container = styled.div`
	padding: 24px;
	overflow: auto;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding-bottom: 100px;
	}
`;

const LineItem = styled.div`
	margin: 16px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Label = styled.div`
	font-size: 12px;
`;

const Value = styled.div`
	font-size: 16px;
`;

const WarningText = styled.div`
	font-size: 14px;
	color: ${colors.pink};
	text-align: center;
	margin: auto;
`;

const ModalActionBar = styled.div`
	flex-shrink: 0;
	border-top: 1px solid ${colors.grey10};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 24px;
	padding-top: 16px;
	gap: 16px;

	@media (max-width: 768px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: white;
		min-width: 320px;
	}
`;

const BankDetailsLabel = styled(Label)`
	font-size: 14px;
`;

const BankDetailsValue = styled(Value)`
	font-size: 14px;
`;

const CopyContentContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
`;

const LoaderContainer = styled.div`
	margin: auto;
	margin-top: 24px;
	margin-bottom: 24px;
`;

const ButtonContent = styled.div`
	display: flex;
	align-items: center;
`;

const Text = styled.p`
	color: ${colors.grey60};
`;
