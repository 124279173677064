import React, { useState } from 'react';

import api from 'api';
import styled from 'styled-components';

import theme from 'config/theme';
import Button from 'lib/Button';
import { SimpleRoom } from 'models/property';
import { TopBar } from 'lib/TopBar';
import modalContainer from 'containers/modal';
import ModalEnum from 'models/modalEnum';
import { notifyFailure, notifySuccess } from 'lib/Notifications';

import AvailabilityModalContent from './AvailabilityModalContent';

const { colors, fontWeights } = theme;

interface AvailabilityModalProps {
	uuid: string;
	title: string;
	subType: string;
	rooms: SimpleRoom[];
	placesAvailable: number;
	roomsAvailable: number;
	isActive: boolean;
	onClose(): void;
	onSave(val: any, ignoreReload?: boolean): void;
	bottomSheet: boolean;
}

export const AvailabilityModal = ({
	uuid,
	title,
	subType,
	rooms,
	placesAvailable,
	roomsAvailable,
	isActive,
	onClose,
	onSave,
	bottomSheet,
}: AvailabilityModalProps) => {
	const [availablePlaces, setAvailablePlaces] = useState(placesAvailable ?? 0);
	const [availableRooms, setAvailableRooms] = useState(roomsAvailable ?? 0);
	const [activeStateOfRooms, setActiveStateOfRooms] = useState(rooms);
	const [isLoading, setIsLoading] = useState(false);

	const handleUpdateAvailability = (val: number) => {
		setAvailableRooms(availableRooms + val);
	};

	const handleRoomsUpdate = (updatedRooms: SimpleRoom[]) => {
		setActiveStateOfRooms(updatedRooms);
	};

	const handleSave = () => {
		if (availablePlaces === 0 && isActive) {
			onClose();
			modalContainer.open(ModalEnum.Generic, maxCapacityModalContent(), { width: 500 });
			return;
		}

		if (availableRooms === 0 && isActive) {
			onClose();
			modalContainer.open(ModalEnum.Generic, allRoomsInactiveModalContent(), { width: 500 });
			return;
		}

		setIsLoading(true);
		api.property
			.updateCapacity(
				uuid,
				availablePlaces,
				activeStateOfRooms.map(room => {
					return { uuid: room.uuid, active: room.active };
				}),
			)
			.then(res => {
				onSave(res.data);
			})
			.catch(e => {
				setIsLoading(true);
				console.log(e);
			});
	};

	const handleDeactivate = () => {
		api.property
			.updateCapacity(
				uuid,
				availablePlaces,
				activeStateOfRooms.map(room => {
					return { uuid: room.uuid, active: room.active };
				}),
			)
			.then(() => api.property.updateSearchable(uuid))
			.then(res => {
				setIsLoading(false);
				if (res.data.searchable) {
					if (res.data.errors?.length) {
						notifyFailure(`Error deactivating listing. ${res.data.errors[0].error}`);
						return;
					}
					notifyFailure('Error deactivating listing. Please try again.');
					return;
				}

				notifySuccess('Listing deactivated successfully');
				onSave(undefined);
				modalContainer.close();
			})
			.catch(e => {
				console.log(e);
				setIsLoading(false);
				notifyFailure('Error deactivating listing. Please try again.');
			});
	};

	const maxCapacityModalContent = () => {
		return (
			<>
				<TopBar title="No places available" closeOnRight onClose={modalContainer.close} />
				<ModalContentContainer>
					<SubTitle>
						You have indicated this listing has no more available places. Do you want to deactivate
						this listing?
					</SubTitle>
					<AccentSubtitle>You can still chat to your active enquirers in the inbox.</AccentSubtitle>
				</ModalContentContainer>
				<ModalActionBar>
					<Button maxWidth={200} isOutline onClick={modalContainer.close}>
						Cancel
					</Button>
					<Button isLoading={isLoading} maxWidth={200} onClick={handleDeactivate}>
						Deactivate
					</Button>
				</ModalActionBar>
			</>
		);
	};

	const allRoomsInactiveModalContent = () => {
		return (
			<>
				<TopBar title="All rooms inactive" closeOnRight onClose={modalContainer.close} />
				<ModalContentContainer>
					<SubTitle>
						You have indicated no more rooms are available for this listing. Do you want deactivate
						this listing?
					</SubTitle>
					<AccentSubtitle>You can still chat to your active enquirers in the inbox.</AccentSubtitle>
				</ModalContentContainer>
				<ModalActionBar>
					<Button maxWidth={200} isOutline onClick={modalContainer.close}>
						Cancel
					</Button>
					<Button isLoading={isLoading} maxWidth={200} onClick={handleDeactivate}>
						Deactivate
					</Button>
				</ModalActionBar>
			</>
		);
	};

	return (
		<>
			<TopBar title="Availability" closeOnRight onClose={onClose} />
			<SubTitle>{title}</SubTitle>
			<SubType>{subType}</SubType>
			<AvailabilityModalContent
				availablePlaces={availablePlaces}
				rooms={activeStateOfRooms}
				onRoomActiveChange={handleUpdateAvailability}
				onUpdateRoomStatus={handleRoomsUpdate}
				onAvailabilityChange={setAvailablePlaces}
				id={uuid}
			/>
			<ModalActionBar>
				<Button isLoading={isLoading} maxWidth={180} onClick={handleSave}>
					Save
				</Button>
			</ModalActionBar>
		</>
	);
};

export default AvailabilityModal;

const ModalActionBar = styled.div`
	flex-shrink: 0;
	border-top: 1px solid ${colors.grey10};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 24px;
	padding-top: 16px;
	gap: 16px;

	@media (max-width: 768px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: white;
		min-width: 320px;
	}
`;

const SubTitle = styled.div`
	text-align: center;
	font-weight: ${fontWeights.thin};
	font-size: 16px;
	margin: auto;
	margin-top: 16px;
	width: 100%;
`;

const SubType = styled(SubTitle)`
	font-weight: ${fontWeights.bold};
	border-top: 1px solid ${colors.grey10};
	border-bottom: 1px solid ${colors.grey10};
	text-transform: capitalize;
	margin-top: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
`;

const AccentSubtitle = styled(SubTitle)`
	color: ${colors.darkTurquoise};
	font-weight: 600;
	margin-bottom: 24px;
`;

const ModalContentContainer = styled.div`
	max-width: 400px;
	margin: 24px auto;
	padding: 0 24px;
`;
