import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'config/theme';
import useRouter from 'hooks/useRouter';
import routes from 'config/routes';
import { BREAKPOINT_MOBILE_PX, BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import { Invoice, InvoicesResponse } from 'models/invoices';
import Divider from 'lib/Divider';
import modalContainer from 'containers/modal';
import InvoiceDetailsModalContent from '../manage-listing-dashboard/InvoiceDetailsModalContent';
import ModalEnum from 'models/modalEnum';
import QueryInvoiceModalContent from '../manage-listing-dashboard/QueryInvoiceModalContent';
import { useScreenWidth } from 'hooks/useScreenWidth';
import dateService from 'services/dateService';

const { colors, fontWeights } = theme;

interface LandlordPendingConversationProps {
	recentInvoices: InvoicesResponse;
}

const LandlordRecentPlacements = ({ recentInvoices }: LandlordPendingConversationProps) => {
	const [goTo] = useRouter();
	const width = useScreenWidth();
	const isMobile = width <= BREAKPOINT_TABLET_PX;
	// TODO: Move .slice logic out of the function logic
	const [invoices, setInvoices] = useState<Invoice[]>(
		isMobile
			? recentInvoices.results.length >= 4
				? recentInvoices.results.slice(0, 4)
				: recentInvoices.results
			: recentInvoices.results.length >= 8
			? recentInvoices.results.slice(0, 8)
			: recentInvoices.results,
	);
	const handleQueryInvoice = (invoice: Invoice) => {
		if (invoice.queried) {
			return;
		}

		modalContainer.open(
			ModalEnum.Generic,
			<QueryInvoiceModalContent
				invoice={invoice}
				onClose={modalContainer.close}
				onQueried={() => {
					setInvoices(items =>
						items.map(item => ({
							...item,
							queried: item.uuid === invoice.uuid ? true : item.queried,
						})),
					);
					modalContainer.close();
				}}
			/>,
			{ width: 500 },
		);
	};
	const handleInvoiceClick = (invoice: Invoice) => {
		modalContainer.open(
			ModalEnum.Generic,
			<InvoiceDetailsModalContent
				invoice={invoice}
				onClose={modalContainer.close}
				onQueryClick={() => handleQueryInvoice(invoice)}
			/>,
			{ width: 500 },
		);
	};
	return (
		<Container empty={invoices.length == 0}>
			<HeaderContainer>
				<TitleContainer>Recent placements</TitleContainer>
				<ViewInvoiceContainer onClick={() => goTo(routes.manage_listing_invoices)}>
					View invoices
				</ViewInvoiceContainer>
			</HeaderContainer>
			{invoices.length > 0 && (
				<TableHeaders>
					<TableProfileHeader />
					<TableHeaderItem flex={3}>Tenant name</TableHeaderItem>
					<TableHeaderItem flex={2}>Move in date</TableHeaderItem>
					<TableHeaderItem>Lease period</TableHeaderItem>
					<TableHeaderItem flex={3}>Listing</TableHeaderItem>
					<TableHeaderItem />
				</TableHeaders>
			)}
			{invoices.length > 0 ? (
				invoices.map(invoice => (
					<>
						<InvoiceChatRow>
							{!isMobile ? (
								<>
									<ProfilePictureIcon src={invoice.tenant.profile_picture.url} />
									<InvoiceText flex={3} bold>
										{invoice.tenant.fullname}
									</InvoiceText>
									<InvoiceText flex={2}>
										{dateService.formatForDisplay(invoice.occupancy_start_date, true)}
									</InvoiceText>
									<InvoiceText>{invoice.occupancy_duration}</InvoiceText>
									<InvoiceText flex={3}>{invoice.listing_title}</InvoiceText>
									<ViewDetailsContainer onClick={() => handleInvoiceClick(invoice)}>
										View details
									</ViewDetailsContainer>
								</>
							) : (
								<>
									<ProfilePictureIcon src={invoice.tenant.profile_picture.url} />
									<InvoiceMobileInfo>
										<InvoiceMobileInfoHeader>
											<InvoiceText bold>{invoice.tenant.fullname}</InvoiceText>
											<ViewDetailsContainer onClick={() => handleInvoiceClick(invoice)}>
												View details
											</ViewDetailsContainer>
										</InvoiceMobileInfoHeader>
										<InvoiceText>{invoice.listing_title}</InvoiceText>
										<InvoiceText>{invoice.occupancy_duration}</InvoiceText>
									</InvoiceMobileInfo>
								</>
							)}
						</InvoiceChatRow>
						<Divider noMargin />
					</>
				))
			) : (
				<InvoiceContainer>You have no recent placements</InvoiceContainer>
			)}
		</Container>
	);
};

export default LandlordRecentPlacements;

const Container = styled.div<{ empty: boolean }>`
	display: flex;
	gap: ${({ empty }) => (empty ? `32px` : '16px')};
	flex-direction: column;
	width: 100%;
	padding-top: 24px;
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		gap: 24px;
		max-width: ${BREAKPOINT_TABLET_PX};
	}
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 8px;
`;
const InvoiceContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;
	gap: 16px;
`;
const TitleContainer = styled.div`
	display: flex;
	font-weight: ${fontWeights.bold};
	font-size: 24px;
	align-items: center;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex: 2;
		max-height: 36px;
	}
`;

const ViewInvoiceContainer = styled.div`
	display: flex;
	border-radius: 10px;
	box-sizing: border-box;
	border: 2px solid ${colors.darkBlue};
	padding: 8px;
	font-weight: ${fontWeights.bold};
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;

	&:hover {
		cursor: pointer;
	}
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex: 1;
	}
`;

const ProfilePictureIcon = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 40px;
`;
const InvoiceChatRow = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;
const InvoiceText = styled.div<{
	color?: string;
	bold?: boolean;
	small?: boolean;
	flex?: number;
}>`
	font-weight: 400;
	margin-bottom: 4px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	flex-wrap: nowrap;
	color: ${({ color }) => color};
	font-weight: ${({ bold }) => (bold ? 600 : 400)};
	font-size: ${({ small }) => (small ? 14 : 16)}px;
	line-height: 20px;
	flex: ${({ flex }) => (flex ? flex : 1)};
`;

const InvoiceMobileInfoHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 8px;
	width: 100%;
`;
const InvoiceMobileInfo = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const ViewDetailsContainer = styled.div`
	cursor: pointer;
	font-size: 14px;
	width: 64px;
	font-weight: ${fontWeights.bold};
	color: ${colors.linkBlue};
`;

const TableHeaders = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		display: none;
	}
`;

const TableHeaderItem = styled.div<{ flex?: number }>`
	display: flex;
	font-size: 12px;
	flex: ${({ flex }) => (flex ? flex : 1)};
`;

const TableProfileHeader = styled.div`
	width: 40px;
	height: 40px;
`;
