import theme from 'config/theme';
import { BankDetails, InvoiceStatus } from 'models/invoices';

const { colors } = theme;

const getStatusColor = (status: string): string => {
	if (status === InvoiceStatus.Overdue) {
		return colors.red;
	}
	if (status === InvoiceStatus.Outstanding || status === InvoiceStatus.PartiallyPaid) {
		return colors.orange;
	}
	if (status === InvoiceStatus.Paid) {
		return colors.green;
	}
	if (status === InvoiceStatus.Voided) {
		return colors.grey60;
	}
	if (status === InvoiceStatus.Draft) {
		return colors.yellow;
	}
	return colors.darkBlue;
};

const getBankDetails = (): BankDetails => ({
	accountName: 'DigsConnect',
	bank: 'First National Bank (FNB)',
	accountNumber: '62753577942',
	branchCode: '250655',
	bankShort: 'FNB',
});

const invoiceService = {
	getStatusColor,
	getBankDetails,
};

export default invoiceService;
