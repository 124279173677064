import { BREAKPOINT_MOBILE_PX, BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import { PaginatedInbox } from 'models/inbox';
import { InvoicesResponse } from 'models/invoices';
import React from 'react';
import styled from 'styled-components';
import LandlordPendingConversations from './LandlordPendingConversations';
import LandlordRecentPlacements from './LandlordRecentPlacements';

interface LandlordPlacementsProps {
	conversations: PaginatedInbox;
	invoices: InvoicesResponse;
}

const LandlordPlacements = ({ conversations, invoices }: LandlordPlacementsProps) => {
	return (
		<Container>
			<LandlordPendingConversations conversations={conversations} />
			<LandlordRecentPlacements recentInvoices={invoices} />
		</Container>
	);
};

export default LandlordPlacements;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	gap: 48px;
	padding: 24px;
	padding-bottom: 0px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: column;
		padding: 16px;
	}
`;
