import React from 'react';

import styled from 'styled-components';
import theme from 'config/theme';
import { BREAKPOINT_MOBILE_PX, BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import { useScreenWidth } from 'hooks/useScreenWidth';
import InfoIconPopup from 'components/popups/InfoIconPopup';
import { ReactComponent as InfoIcon } from 'assets/images/landlord-dashboard/InfoIcon.svg';
import modalContainer from 'containers/modal';
import { ModalType } from 'constants/modalTypes';

const { colors, fontWeights } = theme;

interface PerformanceCardProps {
	infoIconText?: string;
	icon?: React.ReactNode;
	title?: string;
	description?: string;
	descriptionColor?: string;
	isDescriptionLink?: boolean;
	isGridArea?: boolean;
	onClick?: () => void;
}
const PerformanceCard = ({
	infoIconText,
	icon,
	title,
	description,
	descriptionColor,
	isDescriptionLink,
	isGridArea,
	onClick,
}: PerformanceCardProps) => {
	const width = useScreenWidth();
	const isMobile = width < BREAKPOINT_TABLET_PX;
	return (
		<Container isGridArea={isGridArea}>
			<IconContainer>
				<PerformanceIcon>{icon}</PerformanceIcon>
				{infoIconText && isMobile && (
					<MobileInfoIcon
						onClick={() => {
							modalContainer.set(ModalType.InfoPopup, {
								popupText: infoIconText,
								popupTitle: title,
							});
						}}
					>
						<InfoIcon />
					</MobileInfoIcon>
				)}
			</IconContainer>
			<PerformanceTextContainer>
				<PerformanceTextTitle>
					{title}
					{infoIconText && (
						<DesktopInfoIcon>
							<InfoIconPopup onHoverText={infoIconText} />
						</DesktopInfoIcon>
					)}
				</PerformanceTextTitle>
				<PerformanceTextValue color={descriptionColor} isLink={isDescriptionLink} onClick={onClick}>
					{description}
				</PerformanceTextValue>
			</PerformanceTextContainer>
		</Container>
	);
};

export default PerformanceCard;

const Container = styled.div<{ grow?: number; isGridArea?: boolean }>`
	display: flex;
	flex-direction: column;
	flex: ${({ grow }) => (grow ? grow : 1)};
	flex-grow: 1;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		border-radius: 16px;
		padding: 16px;
		gap: 8px;
		grid-column: ${({ isGridArea }) => isGridArea && 'span 2'};
		box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
	}
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		background-color: ${colors.white};
		box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
	}
`;
const PerformanceTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 42px 24px 24px;
	background-color: ${colors.white};
	justify-content: center;
	align-items: flex-start;
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
	border-radius: 15px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		margin-top: 0px;
		box-shadow: none;
		padding: 0px;
	}
`;

const PerformanceTextTitle = styled.div`
	display: flex;
	font-size: 16px;
	font-color: ${colors.darkBlue};
	font-weight: ${fontWeights.bold};
	align-items: center;
	justify-content: space-between;
	text-align: left;
	width: 100%;
`;

const PerformanceIcon = styled.div`
	display: flex;
	background: ${colors.iconBackground};
	align-items: center;
	justify-content: center;
	border-radius: 7.61905px;
	min-width: 64px;
	min-height: 64px;
	z-index: 1;
	margin-bottom: -32px;
	padding: 16px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		min-width: unset;
		min-height: unset;
		width: 42px;
		height: 42px;
		margin-bottom: 0px;
		padding: 8px;
	}
`;
const PerformanceTextValue = styled.div<{ isLink?: boolean; color?: string }>`
	display: flex;
	font-size: ${({ isLink }) => (isLink ? '14px' : '32px')};
	color: ${({ color }) => (color ? color : colors.darkBlue)};
	font-weight: ${({ isLink }) => (isLink ? fontWeights.normal : fontWeights.bold)};
	&:hover {
		cursor: pointer;
	}
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		justify-content: space-between;
		align-self: stretch;
		align-items: flex-start;
	}
`;

const DesktopInfoIcon = styled.div`
	display: flex;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		display: none;
	}
`;
const MobileInfoIcon = styled.div`
	display: none;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		display: flex;
	}
`;
