import React from 'react';

import { PageMeta } from 'constants/page_meta';
import styled from 'styled-components';
import theme from 'config/theme';
import { BREAKPOINT_MOBILE_PX, BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import modalContainer from 'containers/modal';
import { ModalType } from 'constants/modalTypes';
import PerformanceCard from './PerformanceCard';
import { ReactComponent as CheckedPerson } from 'assets/images/landlord-dashboard/PersonChecked.svg';
import { ReactComponent as TrustedLandlordBadge } from 'assets/images/landlord-dashboard/TrustedLandlordBadge.svg';
import { ReactComponent as Percent } from 'assets/images/landlord-dashboard/Percent.svg';
import { ReactComponent as Message } from 'assets/images/landlord-dashboard/Message.svg';
import { ReactComponent as Timer } from 'assets/images/landlord-dashboard/Timer.svg';
import { ReactComponent as Home } from 'assets/images/landlord-dashboard/Home.svg';
import Button from 'lib/Button';
import { LandlordStatistics } from './LandlordStatistics';

const { colors, fontWeights } = theme;

interface LandlordPerformanceProps {
	stats: LandlordStatistics;
}

const LandlordPerformance = ({ stats }: LandlordPerformanceProps) => {
	return (
		<>
			<Container>
				<InnerContainer>
					<StatsTextContainer>
						<StatsText isTitle>Landlord Stats</StatsText>
						<StatsText>This is your overall landlord performance on DigsConnect.</StatsText>
					</StatsTextContainer>
					{/* TODO: Map these from the LandlordStatistics file.*/}
					<PerformanceContainer>
						<PerformanceCardContainer isTrusted={stats?.is_trusted_landlord}>
							<PerformanceCard
								title="Active listings"
								description={stats.active_listings.toString()}
								infoIconText="The number of active listings currently being shown to potential tenants. You can edit this in the Manage Listings tab."
								icon={<Home />}
							/>
							<PerformanceCard
								title="Response time"
								description={stats.response_time_hours.toString() + ' hrs'}
								infoIconText="The average time it takes you to reply to an enquiry from a potential tenant."
								icon={<Timer />}
							/>
							<PerformanceCard
								title="Response rate"
								description={stats.response_rate_percentage.toString() + '%'}
								infoIconText="The average amount of enquiries you respond to from potential tenants."
								icon={<Message />}
							/>
							<PerformanceCard
								title="Landlord rank"
								description={stats?.rank_readable}
								infoIconText="Your reply rate, reply time and trusted landlord status make up your landlord rank. This influences where your listings show in search results on the platform."
								descriptionColor={stats?.rank_colour}
								icon={<Percent />}
							/>
							{stats?.is_trusted_landlord && (
								<PerformanceCard
									title="Trusted landlord"
									description="Verified"
									infoIconText="Being a trusted landlord improves your landlord rank and increases the likelihood of potential tenants seeing your listings."
									icon={<CheckedPerson />}
									isGridArea
								/>
							)}
						</PerformanceCardContainer>
					</PerformanceContainer>
				</InnerContainer>
				{!stats?.is_trusted_landlord && (
					<TrustedLandlordContainer>
						<TrustedLandlordBadge />
						<TrustedLandlordTitle>Become a trusted landlord</TrustedLandlordTitle>
						<TrustedLandlordInfoText>
							Place a tenant (and pay your invoices) to become a trusted landlord.
						</TrustedLandlordInfoText>
						<Button
							isOutline
							whiteBackground={false}
							onClick={() => modalContainer.set(ModalType.TrustedLandlord)}
						>
							Find out more
						</Button>
					</TrustedLandlordContainer>
				)}
			</Container>
		</>
	);
};

export default LandlordPerformance;

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	background: ${colors.darkTurquoiseFaded};
	gap: 48px;
	align-items: flex-start;
	padding: 24px;
	border-radius: 16px;
	justify-content: center;
	width: 100%;
	align-self: stretch;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: column;
		border-radius: 0px;
	}
`;

const Container = styled.div`
	display: flex;
	gap: 24px;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: column;
	}
`;
const StatsTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: flex-start;
`;
const StatsText = styled.div<{ isTitle?: boolean }>`
	display: flex;
	color: ${colors.darkBlue};
	font-size: ${({ isTitle }) => (isTitle ? '25px' : '16px')};
	font-weight: ${({ isTitle }) => (isTitle ? fontWeights.bold : fontWeights.normal)};
	align-items: flex-start;
	text-align: left;
`;

const PerformanceContainer = styled.div<{ isTrusted?: boolean }>`
	display: flex;
	gap: 16px;
	align-items: center;
	width: 100%;
	flex: 1;
	flex-grow: 1;
	flex-shrink: 0;
	justify-content: center;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		display: grid;
		grid-gap: 8px;
	}
`;
const PerformanceCardContainer = styled.div<{ isTrusted?: boolean }>`
	display: grid;
	gap: 16px;
	align-items: flex-start;
	grid-gap: ${({ isTrusted }) => (isTrusted ? '2.5%' : '8/3%')};
	grid-template-columns: ${({ isTrusted }) =>
		isTrusted ? '18% 18% 18% 18% 18%' : '23% 23% 23% 23%'};
	width: 100%;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 8px;
	}
`;

const TrustedLandlordContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 24px;
	border-radius: 16px;
	background: ${colors.darkTurquoiseFaded};
	align-items: flex-start;
	height: 100%;
	width: 240px;
	justify-content: center;
	align-self: stretch;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		margin: 16px;
		width: 100%;
	}
`;

const TrustedLandlordTitle = styled.div`
	text-align: left;
	font-weight: ${fontWeights.bold};
	font-size: 16px;
`;
const TrustedLandlordInfoText = styled.div`
	text-align: left;
	font-size: 16px;
`;
