import React, { useState, useEffect } from 'react';
import 'react-spring-bottom-sheet/dist/style.css';
import styled from 'styled-components';
import api from 'api';
import routes from 'config/routes';
import { ReactComponent as OptionDots } from '../../../assets/images/icons/more-vertical-outline.svg';
import { ReactComponent as UpArrow } from '../../../assets/images/manage-listing/up_arrow.svg';
import { ReactComponent as DownArrow } from '../../../assets/images/manage-listing/down_arrow.svg';
import { ReactComponent as Bin } from '../../../assets/images/manage-listing/bin.svg';
import { ReactComponent as Hint } from '../../../assets/images/manage-listing/question.svg';
import { ReactComponent as RightArrow } from '../../../assets/images/manage-listing/arrow-right.svg';
import { ReactComponent as PlaceholderIcon } from '../../../assets/images/manage-listing/placeholder.svg';
import { ReactComponent as ViewListingIcon } from '../../../assets/images/manage-listing/view_listing.svg';
import Cross from 'lib/Cross';
import Button from 'lib/Button';
import theme from 'config/theme';
import AvailabilityModal from './AvailabilityModal';
import ActivateModal from './ActivateModal';
import DeactivateModal from './DeactivateModal';
import DeleteModal from './DeleteModal';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { LeaseType } from 'components/add-digs/types';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import modalContainer from 'containers/modal';
import ModalEnum from 'models/modalEnum';
import addDigsService from '../../add-digs/addDigsService';
import ProgressBar from 'lib/ProgressBar';
import './style-overrides.css';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { notifySuccess, notifyFailure } from 'lib/Notifications';

const { fontSizes, fontWeights, colors } = theme;

const ManageListing = props => {
	const [showOptions, setShowOptions] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const [showPanel, setShowPanel] = useState(false);
	const [showAvailabilityBottomSheet, setShowAvailabilityBottomSheet] = useState(false);
	const [showActivateBottomSheet, setShowActivateBottomSheet] = useState(false);
	const [roomsAvailable, setRoomsAvailable] = useState(
		props.rooms.filter(room => {
			return room.active;
		}).length ?? 0,
	);
	const [placesAvailable, setPlacesAvailable] = useState(props.total_places_available_value || 0);
	const [progressPercentage, setProgressPercentage] = useState(0);
	const activeEnquiries = props.active_enquiries;
	const screenWidth = useScreenWidth();

	useEffect(() => {
		setPlacesAvailable(props.total_places_available_value);
	}, [props.total_places_available_value]);

	useEffect(() => {
		setShowPanel(props.isExpanded);
	}, [props.isExpanded]);

	useEffect(() => {
		if (!props.uuid || props.complete) {
			return;
		}

		setProgressPercentage(addDigsService.getProgressPercentage(props));
	}, [props.uuid]);

	const handleToggleExpanded = ignoreCompleteCheck => {
		if (!ignoreCompleteCheck && !props.complete) {
			return;
		}
		setShowPanel(!showPanel);
	};

	const handleCompleteListing = () => {
		props.history.push(`${routes.create_listing}/${props.uuid}`);
	};

	const handleViewListing = props => {
		if (props.active) {
			window.open(props.share_url, '_blank');
		}
	};

	const handleClose = (data, ignoreReload) => {
		if (data) {
			const activeRooms = data.rooms.filter(room => {
				return room.active;
			});
			setRoomsAvailable(activeRooms.length);
			setPlacesAvailable(data.total_places_available_value);
		}
		if (!ignoreReload) {
			props.reload();
		}
		modalContainer.close();
		setShowAvailabilityBottomSheet(false);
	};

	const deactivateModal = () => (
		<DeactivateModal
			uuid={props.uuid}
			title={props.title}
			subType={props.sub_type}
			rooms={props.rooms}
			placesAvailable={placesAvailable}
			roomsAvailable={roomsAvailable}
			onClose={modalContainer.close}
			onSave={props.reload}
		/>
	);

	const activateModal = () => (
		<ActivateModal
			uuid={props.uuid}
			title={props.title}
			subType={props.sub_type}
			leaseType={props.lease_type}
			rooms={props.rooms}
			placesAvailable={placesAvailable}
			roomsAvailable={roomsAvailable}
			isMobile={false}
			bottomSheet={false}
			onClose={modalContainer.close}
			onAvailabilityOpen={() =>
				modalContainer.open(
					ModalEnum.Generic,
					<AvailabilityModal
						uuid={props.uuid}
						title={props.title}
						subType={props.sub_type}
						rooms={props.rooms}
						placesAvailable={placesAvailable}
						roomsAvailable={roomsAvailable}
						onClose={modalContainer.close}
						onSave={(data, ignoreReload) => handleClose(data, ignoreReload)}
						isActive={props.active}
						bottomSheet={false}
					/>,
				)
			}
			onSave={props.reload}
		/>
	);

	const deleteModal = () => (
		<DeleteModal
			uuid={props.uuid}
			title={props.title}
			subType={props.sub_type}
			rooms={props.rooms}
			placesAvailable={placesAvailable}
			roomsAvailable={roomsAvailable}
			onSave={props.reload}
			onClose={modalContainer.close}
		/>
	);

	const capitalise = text =>
		text && text.length ? `${text[0].toUpperCase()}${text.slice(1)}` : '';

	const handleActivateLisiting = async () => {
		api.property
			.updateSearchable(props.uuid)
			.then(res => {
				if (!res.data.searchable) {
					if (res.data.errors?.length) {
						notifyFailure(`Error activating listing. ${res.data.errors[0].error}`);
						return;
					}
					notifyFailure('Error activating listing. Please try again.');
					return;
				}

				notifySuccess('Your listing is now live');

				handleClose();
			})
			.catch(e => {
				console.log(e);
				notifyFailure('Error activating listing. Please try again.');
			});
	};

	return (
		<>
			<BottomSheet
				className="bottom-sheet"
				open={showOptions}
				snapPoints={({ minHeight }) => minHeight}
				expandOnContentDrag={false}
			>
				<BottomSheetContainer>
					<BottomSheetHeader>
						<BottomSheetHeaderTextImage>
							<Cross onClick={() => setShowOptions(false)}></Cross>
						</BottomSheetHeaderTextImage>
						<BottomSheetHeaderText>More</BottomSheetHeaderText>
					</BottomSheetHeader>
					{props.active && (
						<BottomSheetButtonContainer>
							<BottomSheetButton onClick={() => handleViewListing(props)}>
								<BottomSheetContainerButtonText>View this listing</BottomSheetContainerButtonText>
							</BottomSheetButton>
						</BottomSheetButtonContainer>
					)}
					<BottomSheetButtonContainer>
						<BottomSheetButton
							onClick={() => {
								setShowOptions(false);
								if (props.active) {
									modalContainer.open(ModalEnum.Generic, deactivateModal());
									return;
								}

								setShowActivateBottomSheet(true);
							}}
						>
							<BottomSheetContainerButtonText>
								{props.active ? 'Deactivate' : 'Activate'} listing
							</BottomSheetContainerButtonText>
						</BottomSheetButton>
					</BottomSheetButtonContainer>
					<BottomSheetDeleteButtonContainer>
						<BottomSheetButton
							onClick={() => {
								setShowOptions(false);
								modalContainer.open(ModalEnum.Generic, deleteModal());
							}}
						>
							<BottomSheetContainerButtonText redText>
								Delete listing
							</BottomSheetContainerButtonText>
						</BottomSheetButton>
					</BottomSheetDeleteButtonContainer>
				</BottomSheetContainer>
			</BottomSheet>
			<BottomSheet
				open={showAvailabilityBottomSheet}
				snapPoints={({ minHeight }) => 800}
				expandOnContentDrag={false}
			>
				<AvailabilityModal
					uuid={props.uuid}
					title={props.title}
					subType={props.sub_type}
					rooms={props.rooms}
					placesAvailable={placesAvailable}
					roomsAvailable={roomsAvailable}
					onClose={() => setShowAvailabilityBottomSheet(false)}
					onSave={(data, ignoreReload) => handleClose(data, ignoreReload)}
					isActive={props.active}
					bottomSheet={false}
				/>
			</BottomSheet>
			<BottomSheet
				open={showActivateBottomSheet}
				snapPoints={({ minHeight }) => 800}
				expandOnContentDrag={false}
			>
				<ActivateModal
					uuid={props.uuid}
					title={props.title}
					subType={props.sub_type}
					rooms={props.rooms}
					leaseType={props.lease_type}
					placesAvailable={placesAvailable}
					roomsAvailable={roomsAvailable}
					isMobile
					bottomSheet
					onClose={() => setShowActivateBottomSheet(false)}
					onAvailabilityOpen={() => {
						setShowActivateBottomSheet(false);
						setShowAvailabilityBottomSheet(true);
					}}
					onSave={props.reload}
				/>
			</BottomSheet>
			<Container
				className={screenWidth > BREAKPOINT_TABLET_PX && props.complete ? 'listing-card' : ''}
				clickable={props.complete}
			>
				<TopPanel onClick={() => handleToggleExpanded()}>
					{props.image ? (
						<Image src={props.image} />
					) : (
						<PlaceholderImageContainer>
							<PlaceholderIcon />
						</PlaceholderImageContainer>
					)}
					<Content>
						<TitleAddressBox active={props.active} onClick={() => handleViewListing(props)}>
							<Title>{props.title || capitalise(props.sub_type)}</Title>
							<Address>
								<span className={screenWidth > BREAKPOINT_TABLET_PX ? 'title-container' : ''}>
									{props.location.formatted_address}
								</span>
							</Address>
							{!props.complete && <ProgressBar percentage={progressPercentage} />}
						</TitleAddressBox>
						{props.complete ? (
							<>
								<AvailabilityContainer>
									<CapacityContainer
										style={{
											visibility: props.lease_type === LeaseType.EntirePlace ? 'hidden' : 'visible',
										}}
									>
										<NumAvailable>{placesAvailable}</NumAvailable>
										<NumAvailableText>Places available</NumAvailableText>
									</CapacityContainer>
									<Divider
										style={{
											visibility: props.lease_type === LeaseType.EntirePlace ? 'hidden' : 'visible',
										}}
									/>
									<CapacityContainer>
										<NumAvailable>{activeEnquiries}</NumAvailable>
										<NumAvailableText>Active enquirers</NumAvailableText>
									</CapacityContainer>
								</AvailabilityContainer>
								{!showPanel ? (
									<ShowPanelButtonContentTop>
										<DownArrow />
									</ShowPanelButtonContentTop>
								) : (
									<ShowPanelButtonContentTop>
										<UpArrow />
									</ShowPanelButtonContentTop>
								)}
							</>
						) : (
							<FinishListingContainer>
								<MarginRightContainer>
									<Button isPink onClick={handleCompleteListing} autoWidth>
										Finish your listing
										<RightArrow />
									</Button>
								</MarginRightContainer>
								<ButtonContainer
									onClick={() => modalContainer.open(ModalEnum.Generic, deleteModal())}
								>
									<Bin />
								</ButtonContainer>
							</FinishListingContainer>
						)}
					</Content>
				</TopPanel>
				{showPanel && (
					<>
						<TabDivider />
						<BottomPanel>
							<Content>
								<AvailabilityContainer
									className={screenWidth >= BREAKPOINT_TABLET_PX ? 'lease-type-container' : ''}
								>
									<TypeContainer>
										<TypeText>Listing type</TypeText>
										<CapitalisedNumAvailable>
											{props.sub_type ? props.sub_type : ''}
										</CapitalisedNumAvailable>
									</TypeContainer>
									<TypeContainer>
										<TypeText>Lease type</TypeText>
										<NumAvailable>
											{props.lease_type == LeaseType.RoomByRoom ? 'Room by room' : 'Entire place'}
										</NumAvailable>
									</TypeContainer>
									<ListingContainer>
										<TypeText>Listing score</TypeText>
										<NumAvailable>{props.score ? props.score : ''}%</NumAvailable>
									</ListingContainer>
									<HintContainer>
										<Hint />
										<Tooltip>
											<p>Listing scores are determined by</p>
											<ul>
												<li>The speed of your responses</li>
												<li>The detailing of your listed property</li>
												<li>The quality of your images</li>
												<li>Added extras like a walk-through video</li>
											</ul>
											<TooltipText>
												For tips and hints on creating incredible <br />
												listings, check out our&nbsp;
												<a href="https://www.digsconnect.com/landlord-resources">
													Landlord Resources
												</a>
											</TooltipText>
										</Tooltip>
									</HintContainer>
								</AvailabilityContainer>
								<ShowPanelButtonContentBottom>
									<ButtonContainer>
										{props.lease_type == LeaseType.RoomByRoom && (
											<Button
												className={screenWidth >= BREAKPOINT_TABLET_PX ? 'availability-button' : ''}
												onClick={() =>
													modalContainer.open(
														ModalEnum.Generic,
														<AvailabilityModal
															uuid={props.uuid}
															title={props.title}
															subType={props.sub_type}
															rooms={props.rooms}
															placesAvailable={placesAvailable}
															roomsAvailable={roomsAvailable}
															onClose={modalContainer.close}
															onSave={(data, ignoreReload) => handleClose(data, ignoreReload)}
															isActive={props.active}
															bottomSheet={false}
														/>,
													)
												}
												autoWidth
											>
												Update availability
											</Button>
										)}
									</ButtonContainer>
									<ButtonContainer minWidth={88}>
										<Button
											className={screenWidth >= BREAKPOINT_TABLET_PX ? 'edit-button' : ''}
											isOutline
											link={routes.edit_digs.replace(':id?', `${props.uuid}`)}
											isFullWidth
										>
											Edit
										</Button>
									</ButtonContainer>
									<ButtonContainer>
										{props.active ? (
											<Button
												className={screenWidth >= BREAKPOINT_TABLET_PX ? 'deactivate-button' : ''}
												isOutline
												onClick={() => modalContainer.open(ModalEnum.Generic, deactivateModal())}
												autoWidth
											>
												Deactivate
											</Button>
										) : (
											<Button
												className={screenWidth >= BREAKPOINT_TABLET_PX ? 'deactivate-button' : ''}
												isOutline
												onClick={() => {
													if (props.lease_type === LeaseType.EntirePlace) {
														handleActivateLisiting();
														return;
													}

													modalContainer.open(ModalEnum.Generic, activateModal());
												}}
												autoWidth
												isDisabled={props.isUnderModeration}
											>
												Activate
											</Button>
										)}
									</ButtonContainer>
									<ButtonContainer
										onClick={() => modalContainer.open(ModalEnum.Generic, deleteModal())}
									>
										<Bin />
									</ButtonContainer>
								</ShowPanelButtonContentBottom>
							</Content>
						</BottomPanel>
					</>
				)}
			</Container>
			<MobileContainer
				className={screenWidth < BREAKPOINT_TABLET_PX && props.complete ? 'listing-card' : ''}
			>
				<TopPanel onClick={() => handleToggleExpanded(true)}>
					<ListingInfoContainer>
						{props.image ? (
							<Image src={props.image} />
						) : (
							<PlaceholderImageContainer>
								<PlaceholderIcon />
							</PlaceholderImageContainer>
						)}
						<Content>
							<TitleContainer>
								<Title>{props.title || capitalise(props.sub_type)}</Title>
								{!props.complete && <ProgressBar percentage={progressPercentage} />}
							</TitleContainer>
						</Content>
					</ListingInfoContainer>
					{!showPanel ? (
						<ShowPanelButtonContentTop>
							<DownArrow />
						</ShowPanelButtonContentTop>
					) : (
						<ShowPanelButtonContentTop>
							<UpArrow />
						</ShowPanelButtonContentTop>
					)}
				</TopPanel>
				{showPanel && (
					<BottomPanel>
						{props.complete ? (
							<>
								<Content>
									<AvailabilityContainer
										className={screenWidth < BREAKPOINT_TABLET_PX ? 'lease-type-container' : ''}
									>
										<TypeContainer>
											<TypeText>Listing type</TypeText>
											<CapitalisedNumAvailable>
												{props.sub_type ? props.sub_type : ''}
											</CapitalisedNumAvailable>
										</TypeContainer>
										<TypeContainer>
											<TypeText>Lease type</TypeText>
											<NumAvailable>
												{props.lease_type == LeaseType.RoomByRoom ? 'Room by room' : 'Entire place'}
											</NumAvailable>
										</TypeContainer>
										<ListingContainer>
											<TypeText>Listing score</TypeText>
											<ScoreContainer>
												<NumAvailable>{props.score ? props.score : ''}%</NumAvailable>
												<HintContainer>
													<Hint
														onClick={() => {
															setShowTooltip(!showTooltip);
														}}
													/>
													{showTooltip && (
														<Tooltip>
															<p>Listing scores are determined by</p>
															<ul>
																<li>The speed of your responses</li>
																<li>The detailing of your listed property</li>
																<li>The quality of your images</li>
																<li>Added extras like a walk-through video</li>
															</ul>
															<TooltipText>
																For tips and hints on creating incredible <br />
																listings. Check out our&nbsp;
																<a href="https://www.digsconnect.com/landlord-resources">
																	Landlord Resources
																</a>
															</TooltipText>
														</Tooltip>
													)}
												</HintContainer>
											</ScoreContainer>
										</ListingContainer>
									</AvailabilityContainer>
								</Content>
								<PlacesRoomsContainer>
									{props.lease_type !== LeaseType.EntirePlace && (
										<>
											<CapacityNoMarginContainer>
												<MobileAvailabiltyNumber>{placesAvailable}</MobileAvailabiltyNumber>
												<NumAvailableText>Places available</NumAvailableText>
											</CapacityNoMarginContainer>
											<Divider />
										</>
									)}
									<CapacityContainer>
										<MobileAvailabiltyNumber>{activeEnquiries}</MobileAvailabiltyNumber>
										<NumAvailableText>Active enquirers</NumAvailableText>
									</CapacityContainer>
								</PlacesRoomsContainer>
								<Content>
									<ShowPanelButtonContentBottom>
										<ActionsContainer>
											{props.lease_type == LeaseType.RoomByRoom && (
												<MarginBottom>
													<Button
														height={32}
														className={
															screenWidth < BREAKPOINT_TABLET_PX ? 'availability-button' : ''
														}
														onClick={() => setShowAvailabilityBottomSheet(true)}
														isFullWidth
													>
														Update availability
													</Button>
												</MarginBottom>
											)}
											<Button
												isOutline
												className={screenWidth < BREAKPOINT_TABLET_PX ? 'edit-button' : ''}
												height={32}
												link={routes.edit_digs.replace(':id?', `${props.uuid}`)}
												isFullWidth
											>
												Edit
											</Button>
										</ActionsContainer>
									</ShowPanelButtonContentBottom>
									<OptionsContainer
										className={screenWidth < BREAKPOINT_TABLET_PX ? 'deactivate-button' : ''}
									>
										<OptionsContainer
											className={screenWidth < BREAKPOINT_TABLET_PX ? 'title-container' : ''}
										>
											<OptionsButton onClick={() => setShowOptions(!showOptions)} />
										</OptionsContainer>
									</OptionsContainer>
								</Content>
							</>
						) : (
							<FinishListingContainer>
								<MarginLeftContainer>
									<Button isPink onClick={handleCompleteListing} autoWidth>
										Finish your listing
										<RightArrow />
									</Button>
								</MarginLeftContainer>
								<ButtonContainer
									onClick={() => modalContainer.open(ModalEnum.Generic, deleteModal())}
								>
									<Bin />
								</ButtonContainer>
							</FinishListingContainer>
						)}
					</BottomPanel>
				)}
			</MobileContainer>
		</>
	);
};

export default ManageListing;

const Container = styled.div`
	border-radius: 16px;
	box-shadow: rgba(0, 0, 0, 0.14) 0 0 10px;
	background-color: white;
	overflow: visible;
	padding: 16px;
	padding-right: 20px;
	align-items: center;
	margin-bottom: 16px;
	z-index: 2;
	cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		padding: 8px;
		display: none;
	}
`;
const OptionsContainer = styled.div``;
const BottomSheetContainer = styled.div`
	justify-content: left;
	align-items: left;
	background-color: white;
`;
const BottomSheetContainerButtonText = styled.div`
	font-size: 16px;
	margin-left: 28px;
	color: ${colors.darkBlue};
	font-weight: 500;
	${({ redText }) => (redText ? `color: ${colors.red};` : '')}
`;
const BottomSheetHeader = styled.div`
	display: flex;
	padding: 16px;
	position: relative;
	width: 100%;
	justify-content: center;
	align-items: center;
`;
const BottomSheetHeaderText = styled.div`
	font-size: ${fontSizes.large};
	font-weight: ${fontWeights.bold};
	text-align: center;
`;
const BottomSheetHeaderTextImage = styled.div`
	margin-right: 24px;
	position: absolute;
	right: 0;
`;
const BottomSheetButtonContainer = styled.div`
	display: flex;
	border-top: 2px solid ${({ color }) => color || colors.grey01};
	padding: 16px;
`;
const BottomSheetDeleteButtonContainer = styled.div`
	display: flex;
	border-top: 2px solid ${({ color }) => color || colors.grey01};
	padding: 16px;
	padding-left: 18px;
`;
const BottomSheetButton = styled.div`
	background-color: ${colors.white};
	cursor: pointer;
`;
const TopPanel = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;
const BottomPanel = styled.div`
	background-color: white;
	display: flex;
	cursor: auto;
	margin-top: 10px;
	align-items: center;
	width: 100%;
	overflow: visible;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: column;
		padding-top: 10px;
		border-top: 1px solid;
		border-top-color: ${colors.grey10};
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	margin-left: 8px;
	cursor: pointer;
	${({ minWidth }) => (minWidth ? `min-width: ${minWidth}px;` : '')}
`;

const MarginRightContainer = styled.div`
	margin-right: 8px;
`;

const MarginLeftContainer = styled.div`
	margin-left: 16px;
	width: 100%;
`;

const MarginBottom = styled.div`
	margin-bottom: 16px;
`;
const MobileContainer = styled(Container)`
	display: none;
	cursor: pointer;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		display: flex;
		flex-direction: column;
		padding: 16px;
	}
`;
const ScoreContainer = styled.div`
	display: flex;
	align-items: center;
`;
const Content = styled.div`
	padding: 0 20px;
	padding-right: 0px;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		padding: 0;
		position: relative;
		justify-content: center;
	}
`;
const OptionsButton = styled(OptionDots)`
	width: 24px;
	height: 24px;
	margin-left: 8px;
	margin-right: 8px;
	flex-shrink: 0;
	cursor: pointer;
`;

const PlaceholderImageContainer = styled.div`
	border-radius: 16px;
	background: ${colors.darkTurquoiseFaded};
	display: flex;
	justify-content: center;
	align-items: center;
	width: 128px;
	height: 80px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		height: 90%;
		min-width: 88px;
		margin-right: 16px;
	}
`;

const Image = styled(PlaceholderImageContainer)`
	background: white;
	background-image: ${({ src }) => `url("${src}")`};
	background-size: cover;
	background-position: center;
`;

const TitleAddressBox = styled.div`
	width: 50%;

	:hover {
		text-decoration: ${props => (props.active ? 'underline' : 'none')};
		color: ${props => (props.active ? colors.darkTurquoise : colors.darkBlue)};
	}
`;

const Divider = styled.div`
	border-right: 1px solid ${colors.grey10};
	height: 60px;
	margin-left: 16px;
`;
const NumAvailable = styled.div`
	font-size: 20px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		font-size: 16px;
	}
`;

const InfoPopup = styled.div`
	position: absolute;
	padding: 8px;
	border-radius: 12px;
	background: ${colors.white};
	left: 0;
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
	color: ${colors.darkBlue};
	line-height: 150%;
	z-index: 1;
	min-width: 200px;
	visibility: hidden;
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
`;
const ViewListingPopup = styled(InfoPopup)`
	top: 32px;
	min-width: unset;
	width: auto;
	white-space: nowrap;
`;

const CapitalisedNumAvailable = styled(NumAvailable)`
	text-transform: capitalize;
`;
const NumAvailableText = styled.div`
	margin-top: 10px;
	font-size: ${fontSizes.small};
`;
const MobileAvailabiltyNumber = styled.div`
	font-size: 25px;
`;
const TypeText = styled.div`
	margin-bottom: 10px;
	font-size: 12px;
	white-space: nowrap;
`;
const Title = styled.div`
	font-weight: 700;
	font-size: ${fontSizes.medium};
	margin-bottom: 8px;
`;
const Address = styled.div`
	font-weight: 400;
	font-size: ${fontSizes.small};
	margin-bottom: 8px;
`;
const ViewListingContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	&:hover ${ViewListingPopup} {
		visibility: visible;
	}
`;
const AvailabilityContainer = styled.div`
	display: flex;
	align-items: center;
`;
const PlacesRoomsContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 24px;
	margin-bottom: 24px;
`;
const TabDivider = styled.div`
	border-bottom: 1px solid;
	border-bottom-color: ${colors.grey10};
	margin-top: 16px;
	margin-bottom: 10px;
`;
const CapacityContainer = styled.div`
	margin-left: 16px;
	text-align: center;
`;
const CapacityNoMarginContainer = styled.div`
	text-align: center;
`;
const TypeContainer = styled.div`
	margin-right: 40px;
	justify-content: flex-start;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		margin-right: 24px;
	}
`;
const ListingContainer = styled.div`
	justify-content: flex-start;
`;
const Tooltip = styled.div`
	color: ${colors.darkBlue};
	background: ${colors.white};
	padding: 8px;
	font-size: 14px;
	border-radius: 10px;
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
	visibility: hidden;
	position: absolute;
	white-space: nowrap;
	bottom: -100px;
	right: -290px;
	z-index: 3;

	ul {
		li {
			margin-left: 20px;
			margin-bottom: 5px;
			list-style-type: disc;
		}
	}

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		right: 20px;
		bottom: 0px;
	}
`;
const TooltipText = styled.p`
	margin-top: 15px;
	line-height: 1.4;
`;
const HintContainer = styled.div`
	min-height: 50px;
	margin-left: 5px;
	align-items: flex-start;
	position: relative;
	display: flex;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		min-height: 16px;
	}

	&:hover ${Tooltip} {
		visibility: visible;
	}
`;
const FinishListingContainer = styled.div`
	display: flex;
	justify-content: flex-end;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		justify-content: space-between;
		width: 100%;
	}
`;
const ShowPanelButtonContentTop = styled.div`
	display: flex;
	align-items: center;
	right: 0;
`;
const ShowPanelButtonContentBottom = styled.div`
	display: flex;
	align-items: center;
	right: 0;
	padding-right: 20px;
	flex: 1;
	gap: 8px;
	justify-content: flex-end;
	padding-left: 8px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		padding-right: 0px;
	}
`;
const ListingInfoContainer = styled.div`
	display: flex;
	align-items: center;
	height: 70px;
	flex: 1;
	margin-right: 24px;
`;

const TitleContainer = styled.div`
	width: 100%;
`;

const ActionsContainer = styled.div`
	margin: 0 8px;
	width: 100%;
`;
