import { Currency } from './listing';

export interface InvoicesResponse {
	count: number;
	next: string;
	previous: string;
	results: Invoice[];
}

export interface Invoice {
	uuid: string;
	invoice_number: string;
	total_amount: string;
	status: InvoiceStatus;
	date_due: string;
	date_invoiced: string;
	listing_title: string;
	room_title: string;
	tenant_name: string;
	tenant: Tenant;
	conversation_url: string;
	queried: any;
	currency: Currency;
	invoice_url: string;
	occupancy_start_date: string;
	occupancy_duration: string;
}

export interface Tenant {
	fullname: string;
	profile_picture: {
		url: string;
	};
}

export interface InvoiceDetail {
	uuid: string;
	base_fee_amount: string;
	currency: Currency;
	credited_amount: string;
	date_due: string;
	date_invoiced: string;
	discount_amount?: string;
	discount_rate_readable?: string;
	fee_exclude_vat_amount: string;
	invoice_number: string;
	invoice_url?: string;
	lease_term: string;
	outstanding_amount: string;
	paid_amount: string;
	queried?: any;
	rental_amount: string;
	status: string;
	subtotal_amount: string;
	tenant_name: string;
	total_amount: string;
	total_lease_amount: number;
	vat_amount: string;
}

export interface OutstandingDetails {
	reference: string;
	total_outstanding: any;
	currency: Currency;
}

export interface BankDetails {
	accountName: string;
	bank: string;
	bankShort: string;
	accountNumber: string;
	branchCode: string;
	reference?: string;
}

export enum InvoiceStatus {
	Overdue = 'Overdue',
	Outstanding = 'Outstanding',
	PartiallyPaid = 'Partially Paid',
	Paid = 'Paid',
	Voided = 'Voided',
	Draft = 'Draft',
}
