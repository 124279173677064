import React from 'react';

interface IconProps {
	active: boolean;
}
const ManageListingIcon = ({ active }: IconProps) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		{active ? (
			<path
				d="M12 2.09961L1 12H4V21H11V15H13V21H20V12H23L12 2.09961ZM12 4.79102L18 10.1914V11V19H15V13H9V19H6V10.1914L12 4.79102Z"
				fill="#041F47"
			/>
		) : (
			<path
				d="M12 2.09961L1 12H4V21H11V15H13V21H20V12H23L12 2.09961ZM12 4.79102L18 10.1914V11V19H15V13H9V19H6V10.1914L12 4.79102Z"
				fill="#CDD2DA"
			/>
		)}
	</svg>
);

export default ManageListingIcon;
