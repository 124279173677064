import React from 'react';

import styled from 'styled-components';
import theme from 'config/theme';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { notifySuccess } from 'lib/Notifications';
import InfoText from 'lib/InfoText';
import Cross from 'lib/Cross';
import invoiceService from './invoiceService';

const { colors } = theme;

interface PayNowModalContentProps {
	onClose(): void;
	reference: string;
	amount: string;
}

const PayNowModalContent = ({ onClose, reference, amount }: PayNowModalContentProps) => {
	const handleCopyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text);
		notifySuccess('Copied to clipboard!');
	};
	return (
		<Container>
			<HeaderContainer>
				<Cross visibility="hidden" />
				<SmallTitleText>DigsConnect banking details</SmallTitleText>
				<Cross onClick={onClose} />
			</HeaderContainer>
			<InfoText textAlign="center">
				Please make payments to DigsConnect using the details below. Payments can take up to 48
				hours to reflect
			</InfoText>
			<InfoBlock>
				<LabelText>Account name</LabelText>
				<ValueText>{invoiceService.getBankDetails().accountName}</ValueText>
			</InfoBlock>
			<InfoBlock>
				<LabelText>Bank</LabelText>
				<ValueText>{invoiceService.getBankDetails().bank}</ValueText>
			</InfoBlock>
			<InfoBlockColumns>
				<InfoBlock halfWidth>
					<LabelText>Acc no.</LabelText>
					<CopyContentContainer
						onClick={() => handleCopyToClipboard(invoiceService.getBankDetails().accountNumber)}
					>
						<ValueText>{invoiceService.getBankDetails().accountNumber}</ValueText>
						<CopyIcon />
					</CopyContentContainer>
				</InfoBlock>
				<InfoBlock halfWidth>
					<LabelText>Branch code</LabelText>
					<CopyContentContainer
						onClick={() => handleCopyToClipboard(invoiceService.getBankDetails().branchCode)}
					>
						<ValueText>{invoiceService.getBankDetails().branchCode}</ValueText>
						<CopyIcon />
					</CopyContentContainer>
				</InfoBlock>
				<InfoBlock halfWidth>
					<LabelText>Ref</LabelText>
					<CopyContentContainer onClick={() => handleCopyToClipboard(reference)}>
						<ValueText>{reference}</ValueText>
						<CopyIcon />
					</CopyContentContainer>
				</InfoBlock>
				<InfoBlock halfWidth>
					<LabelText>Amount</LabelText>
					<ValueText>{amount}</ValueText>
				</InfoBlock>
			</InfoBlockColumns>
		</Container>
	);
};

const LabelText = styled.div`
	font-size: 14px;
	color: ${colors.grey60};
	margin-bottom: 10px;
`;

const ValueText = styled.div`
	font-size: 14px;
	color: ${colors.darkBlue};
	font-weight: 600;
`;

const InfoBlockColumns = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const InfoBlock = styled.div<{ halfWidth?: boolean }>`
	margin-bottom: 16px;
	${({ halfWidth }) => (halfWidth ? 'width: 50%;' : '')}
`;

const CopyContentContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-right: 24px;
	cursor: pointer;
`;

const Container = styled.div`
	padding: 24px;
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
`;

const SmallTitleText = styled.div`
	font-size: 16px;
	font-weight: 600;
`;

export default PayNowModalContent;
