import React from 'react';
import withAuth from 'components/common/with-auth';
import Header from 'components/common/header';
import Footer from 'components/common/footer';

import { Container } from './styles';
import Main from 'components/common/main';
import theme from 'config/theme';
import './student-dashboard.css';
import ManageListingTabs from './ManageListingTabs';

const { colors } = theme;

const ManageListingMain = ({ history }) => (
	<>
		<Header searchable />
		<Main style={{ backgroundColor: `${colors.white}` }}>
			<Container>
				<ManageListingTabs history={history} />
			</Container>
		</Main>
		<Footer />
	</>
);

export default withAuth(ManageListingMain);
