import * as d3 from 'd3';
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { LandlordChartData } from './LandlordChartTypes';
import theme from 'config/theme';
import numberFormatService from 'services/numberFormatService';

interface ChartProps {
	chartData: LandlordChartData;
	id: string;
	graphTitle: string;
	width: number;
	height: number;
	isCurrency?: boolean;
}

const { colors, fontWeights } = theme;

const Chart = ({ chartData, id, graphTitle, width, height, isCurrency }: ChartProps) => {
	const ref = useRef(null);

	const [graphId, setGraphId] = useState<string>('graph' + id);
	const [legendId, setLegendId] = useState<string>('legend' + id);
	const innerRadius = Math.min(width, height) / 2.3; // inner radius of pie, in pixels (non-zero for donut)
	const outerRadius = Math.min(width, height) / 2.1; // outer radius of pie, in pixels
	const radius = Math.min(width, height) / 2.1;
	const pie = d3.pie().sort(null);
	const [isEmptyState, setIsEmptyState] = useState<Boolean>(false);
	const [chartValueLabels, setChartValueLabels] = useState<number[]>(chartData.values);
	const arc = d3
		.arc()
		.innerRadius(innerRadius)
		.outerRadius(outerRadius)
		.cornerRadius(10);

	const emptyArc = d3
		.arc()
		.innerRadius(radius)
		.outerRadius(radius);

	if (chartData.values[0] == 0 && chartData.values[1] == 0) {
		setIsEmptyState(true);
		setChartValueLabels([0, 0]);
		chartData.values[1] = 1;
	}

	useLayoutEffect(() => {
		setGraphId('graph' + id);
		setLegendId('legend' + id);
		const graphContainer = d3.select('#' + graphId);
		graphContainer.selectAll('*').remove();

		const legendContainer = d3.select('#' + legendId);
		legendContainer.selectAll('*').remove();

		const graphSvg = graphContainer
			.append('svg')
			.attr('width', '100%')
			.attr('height', '100%')
			.append('g')
			.attr('transform', 'translate(' + width / 2.0 + ',' + height / 2.0 + ')');

		const legendSvg = legendContainer
			.append('svg')
			.attr('class', 'legend')
			.attr('width', '100%')
			.attr('height', '100%')
			.append('g')
			.attr('transform', 'translate(' + 1.0 + ',' + height / 3.0 + ')');

		if (isCurrency) {
			graphSvg
				.append('svg:text')
				.attr('text-anchor', 'middle')
				.attr('font-weight', 'bold')
				.attr('style', 'font-family:')
				.attr('font-size', '32')
				.attr('fill', colors.darkBlue)
				.text(numberFormatService.formatCurrency(+chartData.total, 'R', true, true));
		} else {
			graphSvg
				.append('svg:text')
				.attr('text-anchor', 'middle')
				.attr('font-weight', 'bold')
				.attr('style', 'font-family:')
				.attr('font-size', '32')
				.attr('fill', colors.darkBlue)
				.text(chartData.total);
		}
		if (isEmptyState) {
			const path = graphSvg
				.selectAll('path')
				.data(pie(chartData.values))
				.enter()
				.append('path')
				.style('stroke', colors.grey60)
				.style('stroke-width', 3)
				.style('stroke-dasharray', '3, 10')
				.attr('d', emptyArc as any)
				.attr('fill', colors.transparent);
		} else {
			const path = graphSvg
				.selectAll('path')
				.data(pie(chartData.values))
				.enter()
				.append('path')
				.attr('d', arc as any)
				.attr('fill', function(d, i) {
					return chartData.colours[i];
				});
		}
		graphSvg
			.append('svg:text')
			.attr('dy', '1.5em')
			.attr('text-anchor', 'middle')
			.attr('style', 'font-family:')
			.attr('font-size', '16')
			.attr('fill', colors.infoGrey)
			.text(graphTitle);

		//LEGEND
		const legendItems = legendSvg
			.selectAll('.legend-items')
			.data(chartData.labels)
			.enter();

		legendItems
			.append('circle')
			.attr('cx', 15)
			.attr('cy', function(d, i) {
				return i * 50;
			})
			.attr('r', 5)
			.style('fill', 'none')
			.style('stroke', function(d, i) {
				return chartData.colours[i];
			})
			.style('stroke-width', 2.5);

		legendItems
			.append('text')
			.attr('x', 25)
			.attr('dy', '0em')
			.attr('y', function(d, i) {
				return i * 50;
			})
			.style('fill', colors.infoGrey)
			.text(function(d, i) {
				return chartData.labels[i];
			})
			.attr('font-weight', 'bold')
			.attr('text-anchor', 'left')
			.style('alignment-baseline', 'middle');

		if (isCurrency) {
			legendItems
				.append('text')
				.attr('x', 10)
				.attr('dy', '1.3em')
				.attr('y', function(d, i) {
					return i * 50;
				})
				.style('fill', colors.black)
				.text(function(d, i) {
					return numberFormatService.formatCurrency(chartValueLabels[i], 'R', true, true);
				})
				.attr('font-weight', 'bold')
				.attr('text-anchor', 'left')
				.style('alignment-baseline', 'middle');
		} else {
			legendItems
				.append('text')
				.attr('x', 10)
				.attr('dy', '1.3em')
				.attr('y', function(d, i) {
					return i * 50;
				})
				.style('fill', colors.black)
				.text(function(d, i) {
					return chartValueLabels[i];
				})
				.attr('font-weight', 'bold')
				.attr('text-anchor', 'left')
				.style('alignment-baseline', 'middle');
		}
	}, [chartData, id, graphTitle]);

	return (
		<Container>
			<GraphContainer id={graphId} ref={ref} width={width} height={height} />
			<LegendContainer id={legendId} width={width} height={height} />
		</Container>
	);
};

export default Chart;

const Container = styled.div`
	display: flex;
	gap: 8px;
	flex-direction: row;
	align-items: center;
`;

const LegendContainer = styled.div<{ width: number; height: number }>`
	display: flex;
	width: ${({ width }) => width - 50}px;
	height: ${({ height }) => height}px;
	align-items: center;
`;
const GraphContainer = styled.div<{ width: number; height: number }>`
	display: flex;
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	align-items: center;
`;
