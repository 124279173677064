import theme from 'config/theme';
import styled from 'styled-components';

const { fontSizes, fontWeights, colors, fonts } = theme;

export const Container = styled.div`
	flex-direction: column;
	margin-bottom: 40px;
	padding: 20px 48px;
  border-top: 1px solid ${colors.grey10};

	@media (max-width: 500px) {
		padding: 40px 24px;
		padding-right: 0px;
		padding-left: 0px;
		width: 100%;
	}
`;

export const Title = styled.h1`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.xxxlarge};
	font-family: ${fonts.Gilroy};
	color: ${colors.darkBlue};
	margin-bottom: 16px;
	text-align: left;
	width: 100%;

	@media (max-width: 500px) {
		font-size: ${fontSizes.xlarge};
		text-align: left;
	}
`;

export const NavContainer = styled.div`
	display: flex;
	justify-content: left;

	@media (max-width: 500px) {
		justify-content: unset;
		flex-wrap: nowrap;
		overflow-x: auto;
		width: 100%;
	}
`;

export const NavItem = styled.li`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.medium};
	color: ${colors.darkBlue};
	text-align: center;
	padding: 30px 30px 30px 0px;
	cursor: pointer;

	${({ active }) =>
		active &&
		`
    color: ${colors.pink};
    & ${Underline} {
      display: block;
    }`}

	@media (max-width: 500px) {
		font-size: ${fontSizes.medium};
		padding-left: 0px;
		white-space: nowrap;
		flex: 0 auto;
	}
`;

export const Underline = styled.div`
	background-color: ${colors.pink};
	border-radius: 10px;
	margin-top: 20px;
	display: none;
	width: 42px;
	height: 4px;
`;

export const FilterContainer = styled.div`
	justify-content: space-between;
	width: 100%;
	display: flex;
	flex-direction: row;

	@media (max-width: 500px) {
		flex-direction: column;
	}
`;

export const SelectLarge = styled.select`
	background-color: ${colors.white};
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.small};
	font-family: ${fonts.Gilroy};
	color: ${colors.darkBlue};

	border-radius: 12px;
	margin-right: 12px;
	padding: 0px 12px;
	min-width: 240px;
	height: 48px;

	outline: none;

	@media (max-width: 500px) {
		margin-bottom: 16px;
		margin-right: 0px;
		width: 100%;
	}
`;

export const SelectContainer = styled.div`
	font-color: ${colors.darkBlue};
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.small};
	width: 80%;
	display: flex;
	flex-direction: row;
	line-height: 48px;

	@media (max-width: 500px) {
		flex-direction: column;
		margin-bottom: 32px;
		margin-top: 16px;
		width: 100%;
	}
`;

export const Select = styled.select`
	background-color: ${colors.white};
	font-weight: ${fontWeights.normal};
	font-size: ${fontSizes.small};
	font-family: ${fonts.Gilroy};
	color: ${colors.darkBlue};

	border-radius: 12px;
	margin-right: 12px;
	padding: 0px 12px;
	height: 48px;

	outline: none;

	@media (max-width: 500px) {
		margin-bottom: 12px;
		margin-right: 0px;
		width: 100%;
	}
`;

export const Border = styled.div`
	background-color: ${colors.gray};
	margin: 34px 0px;
	height: 2px;
	width: 100%;

	@media (max-width: 500px) {
		display: none;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Image = styled.img`
	border-radius: 50%;
	width: 76px;
	height: 76px;

	@media (max-width: 500px) {
		width: 56px;
		height: 56px;
	}
`;
