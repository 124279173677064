import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BREAKPOINT_MOBILE_PX, BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import theme from 'config/theme';
import { ChartTitles, ChartPopupText, LandlordChartData } from './LandlordChartTypes';
import Chart from './Chart';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { ReactComponent as InfoIcon } from 'assets/images/landlord-dashboard/InfoIcon.svg';
import modalContainer from 'containers/modal';
import { ModalType } from 'constants/modalTypes';
import InfoIconPopup from 'components/popups/InfoIconPopup';
import useRouter from 'hooks/useRouter';
import routes from 'config/routes';

const { colors, fontWeights } = theme;

interface LandlordChartProps {
	enquiryData: LandlordChartData[];
}
const LandlordCharts = ({ enquiryData }: LandlordChartProps) => {
	const [goTo] = useRouter();
	const width = useScreenWidth();
	const isMobile = width < BREAKPOINT_TABLET_PX;
	return (
		<Container>
			<HeaderContainer>
				<TitleContainer>Enquiries breakdown</TitleContainer>
				<ViewInboxContainer onClick={() => goTo(routes.inbox)}>
					View inbox
				</ViewInboxContainer>
			</HeaderContainer>

			<OuterGraphContainer>
				<GraphContainer>
					<Chart
						chartData={enquiryData[0]}
						id="ActiveEnquiries"
						graphTitle={ChartTitles.ActiveEnquiries}
						width={isMobile ? 180 : 200}
						height={isMobile ? 180 : 200}
					/>
					{isMobile ? (
						<MobileInfoIcon
							onClick={() => {
								modalContainer.set(ModalType.InfoPopup, {
									popupText: ChartPopupText.ActiveEnquiries,
									popupTitle: 'Enquiries',
								});
							}}
						>
							<InfoIcon />
						</MobileInfoIcon>
					) : (
						<InfoIconPopup onHoverText={ChartPopupText.ActiveEnquiries} />
					)}
				</GraphContainer>
				<GraphContainer>
					<Chart
						chartData={enquiryData[1]}
						id="PotentialPlacements"
						graphTitle={ChartTitles.PotentialPlacements}
						width={isMobile ? 180 : 200}
						height={isMobile ? 180 : 200}
					/>
					{isMobile ? (
						<MobileInfoIcon
							onClick={() => {
								modalContainer.set(ModalType.InfoPopup, {
									popupText: ChartPopupText.PotentialPlacements,
									popupTitle: 'Placements',
								});
							}}
						>
							<InfoIcon />
						</MobileInfoIcon>
					) : (
						<InfoIconPopup onHoverText={ChartPopupText.PotentialPlacements} />
					)}
				</GraphContainer>
				<GraphContainer>
					<Chart
						chartData={enquiryData[2]}
						id="PotentialRevenue"
						graphTitle={ChartTitles.PotentialRevenue}
						width={isMobile ? 180 : 200}
						height={isMobile ? 180 : 200}
						isCurrency
					/>
					{isMobile ? (
						<MobileInfoIcon
							onClick={() => {
								modalContainer.set(ModalType.InfoPopup, {
									popupText: ChartPopupText.PotentialRevenue,
									popupTitle: 'Revenue',
								});
							}}
						>
							<InfoIcon />
						</MobileInfoIcon>
					) : (
						<InfoIconPopup onHoverText={ChartPopupText.PotentialRevenue} />
					)}
				</GraphContainer>
			</OuterGraphContainer>
		</Container>
	);
};

export default LandlordCharts;

const Container = styled.div`
	display: flex;
	gap: 24px;
	align-items: stretch;
	justify-content: space-between;
	width: 100%;
	flex-direction: column;
	padding: 16px;
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: column;
	}
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;
const TitleContainer = styled.div`
	display: flex;
	font-weight: ${fontWeights.bold};
	font-size: 24px;
`;

const ViewInboxContainer = styled.div`
	display: flex;
	border-radius: 10px;
	box-sizing: border-box;
	border: 2px solid ${colors.darkBlue};
	padding: 8px;
	font-weight: ${fontWeights.bold};

	&:hover {
		cursor: pointer;
	}
`;

const OuterGraphContainer = styled.div`
	display: flex;
	gap: 16px;
	flex-direction: row;
	justify-content: space-between;
	padding: 16px;
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: column;
		padding: 8px;
		justify-content: center;
		align-items: center;
	}
`;
const GraphContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const MobileInfoIcon = styled.div`
	display: none;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		display: flex;
	}
`;
