import React, { useState } from 'react';

import api from 'api';
import styled from 'styled-components';

import theme from 'config/theme';
import Button from 'lib/Button';
import { SimpleRoom } from 'models/property';
import { TopBar } from 'lib/TopBar';
import { notifyFailure, notifySuccess } from 'lib/Notifications';

const { colors, fontWeights } = theme;

interface DeactivateModalProps {
	uuid: string;
	rooms: SimpleRoom[];
	placesAvailable: number;
	roomsAvailable: number;
	onSave(): void;
	onClose(): void;
}

const DeactivateModal = ({
	uuid,
	onSave,
	onClose,
}: DeactivateModalProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const handleSave = () => {
		setIsLoading(true);

		api.property
			.updateSearchable(uuid)
			.then(res => {
				setIsLoading(false);
				if (res.data.searchable) {
					if (res.data.errors?.length) {
						notifyFailure(`Error deactivating listing. ${res.data.errors[0].error}`);
						return;
					}
					notifyFailure('Error deactivating listing. Please try again.');
					return;
				}

				notifySuccess('Listing deactivated successfully');
				onSave();
				onClose();
			})
			.catch(e => {
				console.log(e);
				setIsLoading(false);
				notifyFailure('Error deactivating listing. Please try again.');
			});
	};

	return (
		<>
			<TopBar title="Deactivate listing" closeOnRight onClose={onClose} />
			<SubTitle>This property will no longer be searchable by potential tenants.</SubTitle>
			<SubTitle isRed>
				Deactivating will still keep all your active enquiries to this listing open.
			</SubTitle>
			<ModalActionBar>
				<ActivateButtonContainer>
					<Button isOutline maxWidth={162} onClick={onClose}>
						Cancel
					</Button>
				</ActivateButtonContainer>
				<ButtonContainer>
					<Button isFullWidth isLoading={isLoading} onClick={handleSave}>
						Deactivate
					</Button>
				</ButtonContainer>
			</ModalActionBar>
		</>
	);
};

export default DeactivateModal;

const ButtonContainer = styled.div`
	min-width: 150px;
`;

const ActivateButtonContainer = styled.div`
	margin-right: 16px;
	min-width: 150px;
`;

const ModalActionBar = styled.div`
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 24px;

	@media (max-width: 768px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: white;
	}
`;

const SubTitle = styled.div<{ isRed?: boolean }>`
	text-align: center;
	font-weight: ${fontWeights.thin};
	font-size: 16px;
	line-height: 20px;
	padding-right: 32px;
	padding-left: 32px;
	margin-top: 24px;
	color: ${({ isRed }) => (isRed ? `${colors.red}` : `${colors.darkBlue}`)};
	font-weight: ${({ isRed }) => (isRed ? '600' : `${fontWeights.thin}`)};
`;
