import React, { useEffect, useState } from 'react';
import withAuth from 'components/common/with-auth';
import styled from 'styled-components';
import routes from 'config/routes';

import api from 'api';
import { Container } from './styles';
import theme from 'config/theme';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import { Loader } from 'lib/Loader';
import ManageListing from './ManageListing';
import NoFavouritesImageUrl from 'assets/images/favourite-listings/no-favourites.png';
import Plus from 'assets/images/manage-listing/plus.png';
import { Button } from 'lib/Button';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import { ReactComponent as SearchSvg } from 'assets/images/icons/search-outline.svg';
import {
	manageListingProductTourSteps,
	manageListingProductTourStepsEntirePlace,
} from './productTours';
import ProductTour from './ProductTour';
import { ReactComponent as OptionDots } from 'assets/images/icons/more-vertical-outline.svg';
import { BottomSheet } from 'react-spring-bottom-sheet';
import './student-dashboard.css';
import { TopBar } from 'lib/TopBar';
import userContainer from 'containers/user';
import { LeaseType } from 'models/listing';

const { colors, fontWeights } = theme;

const ManageListingDashboard = ({ isLoading, ...props }) => {
	const [term, setTerm] = useState('');
	const [activeListings, setActiveListings] = useState(props.activeData);
	const [inactiveListings, setInactiveListings] = useState(props.inactiveData);
	const [underModerationListings, setUnderModerationListings] = useState(props.underModerationData);
	const [showTour, setShowTour] = useState(false);
	const [showMobileBottomsheet, setShowMobileBottomsheet] = useState(false);

	useEffect(() => {
		const filterFunction = listing => {
			if (!term) {
				return true;
			}
			const address = (listing && listing.location && listing.location.formatted_address) || '';
			return (
				listing.title.toLowerCase().includes(term.toLowerCase()) ||
				address.toLowerCase().includes(term.toLowerCase())
			);
		};
		setActiveListings((props.activeData || []).filter(filterFunction));
		setInactiveListings((props.inactiveData || []).filter(filterFunction));
		setUnderModerationListings((props.underModerationData || []).filter(filterFunction));
	}, [term, props.activeData, props.inactiveData, props.underModerationData]);

	useEffect(() => {
		api.userV2.getProfile().then(response => {
			if (
				(activeListings.length ||
					(inactiveListings || []).filter(listing => listing.complete).length) &&
				!response.data.has_completed_manage_listing_tour
			) {
				handleViewTutorialClick();
			}
		});
	}, []);

	const handleCreateListingClick = () => {
		props.history.push(routes.create_listing);
	};

	const handleSearchInput = value => {
		setTerm(value || '');
	};

	const handleViewTutorialClick = () => {
		if (activeListings.length) {
			setActiveListings(prev =>
				prev.map((listing, i) => ({
					...listing,
					isExpanded: i === 0,
				})),
			);
			setTimeout(() => {
				setShowTour(true);
			}, 0);
			return;
		}

		setInactiveListings(prev => {
			const firstComplete = prev.find(listing => listing.complete);
			if (!firstComplete) {
				return prev;
			}

			return prev.map(listing => ({
				...listing,
				isExpanded: listing.uuid === firstComplete.uuid,
			}));
		});
		setTimeout(() => {
			setShowTour(true);
		}, 0);
	};

	var content = null;

	if (isLoading) {
		return (
			<LoaderContainer>
				<Loader />
			</LoaderContainer>
		);
	}
	if (!props.activeData.length && !props.inactiveData.length && !props.underModerationData.length) {
		content = (
			<>
				<Container>
					<ListingsContainer>
						<img src={NoFavouritesImageUrl} />
						<Caption>You have no added property listings yet</Caption>
						<Text>Add your listings to DigsConnect in order to start receiving enquiries</Text>
						<Button maxWidth={160} isPink onClick={handleCreateListingClick}>
							Create listing
						</Button>
					</ListingsContainer>
				</Container>
			</>
		);
	} else {
		content = (
			<>
				{featureFlagContainer.isEnabled(FeatureFlag.ManageListingProductTour) &&
					(Boolean(activeListings.length) ||
						Boolean((inactiveListings || []).filter(listing => listing.complete).length)) && (
						<MobileTitleRow>
							<MobileTitle>Manage listings</MobileTitle>
							<OptionsButton onClick={() => setShowMobileBottomsheet(true)} />
						</MobileTitleRow>
					)}
				<BottomSheet open={showMobileBottomsheet}>
					<TopBar
						closeOnRight
						smallTitle
						title="More"
						onClose={() => setShowMobileBottomsheet(false)}
					/>
					<BottomsheetRowItem
						onClick={() => {
							handleViewTutorialClick();
							setShowMobileBottomsheet(false);
						}}
					>
						Need help? View tutorial
					</BottomsheetRowItem>
				</BottomSheet>
				<TopContainer>
					<Row>
						<SearchContainer>
							<InputContainer>
								<StandardInput
									type="text"
									placeholder="Search by title or location"
									style={{ width: '100%' }}
									onChange={event => handleSearchInput(event.target.value)}
								/>
								<IconSearchButton>
									<SearchIcon />
								</IconSearchButton>
							</InputContainer>
						</SearchContainer>
						{featureFlagContainer.isEnabled(FeatureFlag.ManageListingProductTour) &&
							(Boolean(activeListings.length) ||
								Boolean((inactiveListings || []).filter(listing => listing.complete).length)) && (
								<HelpText onClick={handleViewTutorialClick}>Need help? View tutorial</HelpText>
							)}
					</Row>
					<ButtonBox>
						<Button autoWidth isGreen onClick={handleCreateListingClick}>
							<PlusImage src={Plus} />
							Create new listing
						</Button>
					</ButtonBox>
					<MobileButtonBox>
						<Button autoWidth isGreen onClick={handleCreateListingClick}>
							<PlusImage src={Plus} />
						</Button>
					</MobileButtonBox>
				</TopContainer>
				<BottomContainer>
					<TitleBox>
						<BottomTitleContainer>
							<BottomTitleBox>
								<BottomTitle>Active listings</BottomTitle>
							</BottomTitleBox>
							<BottomTitleCountBox>
								<BottomTitleCount>({activeListings ? activeListings.length : 0})</BottomTitleCount>
							</BottomTitleCountBox>
						</BottomTitleContainer>
						<SectionInfoMessage>
							Active listings are currently live and showing to potential tenants.
						</SectionInfoMessage>
					</TitleBox>

					{activeListings &&
						activeListings.map((live, i) => (
							<ManageListing
								history={props.history}
								active
								key={live.uuid}
								{...live}
								reload={() => props.onChange()}
							/>
						))}

					{underModerationListings && underModerationListings.length > 0 && (
						<>
							<TitleBox>
								<BottomTitleContainer>
									<BottomTitleBox>
										<BottomTitle>Pending moderation </BottomTitle>
									</BottomTitleBox>
									<BottomTitleCountBox>
										<BottomTitleCount>
											({underModerationListings ? underModerationListings.length : 0})
										</BottomTitleCount>
									</BottomTitleCountBox>
								</BottomTitleContainer>
								<SectionInfoMessage>
									Pending listings are under review, and will be made active soon.
								</SectionInfoMessage>
							</TitleBox>
							{underModerationListings.map((live, i) => (
								<ManageListing
									history={props.history}
									key={live.uuid}
									{...live}
									reload={() => props.onChange()}
									isUnderModeration
								/>
							))}
						</>
					)}
					<TitleBox>
						<BottomTitleContainer>
							<BottomTitleBox>
								<BottomTitle>Inactive listings</BottomTitle>
							</BottomTitleBox>
							<BottomTitleCountBox>
								<BottomTitleCount>
									({inactiveListings ? inactiveListings.length : 0})
								</BottomTitleCount>
							</BottomTitleCountBox>
						</BottomTitleContainer>
						<SectionInfoMessage>
							Inactive listings are deactivated and not shown to potential tenants.
						</SectionInfoMessage>
					</TitleBox>
					{inactiveListings && inactiveListings.length > 0 && (
						<>
							{inactiveListings.map((live, i) => (
								<ManageListing
									history={props.history}
									key={live.uuid}
									{...live}
									reload={() => props.onChange()}
								/>
							))}
						</>
					)}
				</BottomContainer>
			</>
		);
	}

	return (
		<>
			{showTour && (
				<ProductTour
					onComplete={async () => {
						setShowTour(false);
						await api.userV2.updateProfile({
							has_completed_manage_listing_tour: true,
						});
					}}
					productTourSteps={
						(activeListings.length
							? activeListings
							: (inactiveListings || []).filter(listing => listing.complete))[0].lease_type ===
						LeaseType.EntirePlace
							? manageListingProductTourStepsEntirePlace
							: manageListingProductTourSteps
					}
				/>
			)}
			<Main>{content}</Main>
		</>
	);
};

export default withAuth(ManageListingDashboard);

const Main = styled.main`
	background-color: ${colors.white};
	padding-top: 10px;
	@media (max-width: 900px) {
		padding-top: 10px;
	}
`;

const ListingsContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;

const Caption = styled.p`
	text-align: center;
	line-height: 1.2;
	font-weight: ${fontWeights.normal};
	font-size: 20px;
	margin-bottom: 5px;
`;

const Text = styled.p`
	text-align: center;
	font-size: 16px;
	color: ${colors.grey60};
	max-width: 19em;
	line-height: 1.2;
	min-width: 320px;
	margin-bottom: 65px;
`;

const StandardInput = styled.input`
	background-color: ${colors.grey01};
	color: ${colors.darkBlue};
	outline: none;
	flex: 1;
	max-height: 56px;

	::placeholder {
		text-overflow: ellipsis;
	}

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		width: auto;
	}
`;

const InputContainer = styled.div`
	background-color: ${colors.grey01};
	border-radius: 16px;
	padding-left: 16px;
	height: 100%;
	width: 100%;
	display: flex;
`;

const SearchContainer = styled.div`
	display: flex;
	min-height: 56px;
	border-radius: 16px;
	position: relative;
	z-index: 1;
	flex: 1;
	margin-right: 24px;
	max-width: 450px;

	@media (max-width: 400px) {
		height: 100%;
	}
`;

const TopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;

	@media (max-width: 500px) {
		padding-right: 20px;
		padding-left: 20px;
	}
`;

const BottomContainer = styled.div`
	margin-top: 10px;
	width: 100%;
	position: relative;
	z-index: 1;

	@media (max-width: 500px) {
		padding-right: 20px;
		padding-left: 20px;
	}
`;

const BottomTitleContainer = styled.div`
	display: flex;
`;

const BottomTitle = styled.p`
	font-size: 24px;
	font-weight: ${fontWeights.bold};
`;

const BottomTitleCount = styled.p`
	font-size: 24px;
	font-weight: ${fontWeights.bold};
`;

const TitleBox = styled.div`
	display: flex;
	margin-bottom: 16px;
	flex-direction: column;
`;

const BottomTitleBox = styled.div`
	margin-right: 5px;
`;

const BottomTitleCountBox = styled.div``;

const ButtonBox = styled.div`
	right: 0;

	@media (max-width: 500px) {
		display: none;
	}
`;

const MobileButtonBox = styled.div`
	display: none;

	@media (max-width: 500px) {
		display: flex;
		right: 0;
	}
`;

const SearchIcon = styled(SearchSvg)`
	fill: ${colors.grey60};
	height: 20px;
	width: 20px;
`;

const IconSearchButton = styled.button`
	height: 56px;
	background: ${colors.grey01};
	border: none;
	display: flex;
	align-items: center;
	width: 56px;
	padding: 0;
	justify-content: center;
	border-radius: 40px;
	margin-left: 8px;
`;

const PlusImage = styled.img`
	margin-right: 12px;

	@media (max-width: 500px) {
		margin-right: 0px;
	}
`;

const LoaderContainer = styled.div`
	margin-top: 60px;
`;

const SectionInfoMessage = styled.p`
	margin-bottom: 8px;
`;
const EmptyStateMessage = styled.p`
	margin-bottom: 16px;
`;

const HelpText = styled.div`
	font-size: 14px;
	color: ${colors.darkTurquoise};
	font-weight: 600;
	margin-left: 16px;
	cursor: pointer;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		display: none;
	}
`;

const Row = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const MobileTitleRow = styled(Row)`
	justify-content: space-between;
	display: none;
	padding: 0 24px;
	margin-bottom: 16px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		display: flex;
	}
`;

const MobileTitle = styled.div`
	font-size: 24px;
`;

const OptionsButton = styled(OptionDots)`
	width: 24px;
	height: 24px;
	margin-left: 8px;
	margin-right: 8px;
	flex-shrink: 0;
	cursor: pointer;
`;

const BottomsheetRowItem = styled.div`
	padding: 24px 48px;
	cursor: pointer;
`;
